import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'
import useTitle from '../../lib/effects/useTitle'
import BreadcrumbsBar from '../BreadcrumbsBar'
import { useGitLabApi } from '../../gitlab/gitlabApi'
import { classNames } from 'lib/utils'
import FileCreate from 'components/FileCreate'
import { GitFile } from 'core/GitFile'

type FileCreatePageProps<T> = React.PropsWithChildren<{
  gitFile: GitFile<T>
}>

function usePathQueryParam() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const path = params.get('path') || ''
  return path
}

export default function FileCreatePage<T>({
  gitFile,
  children,
}: FileCreatePageProps<T>) {
  const title = gitFile.title
  const basePath = gitFile.basePath
  const history = useHistory()
  const path = usePathQueryParam()
  const { performActions } = useGitLabApi()
  useTitle(`Create ${title}`)

  const authCreateFile = useCallback(
    async (value, relativePath) => {
      const filePath = basePath + '/' + relativePath + '.md'
      const actions = [
        {
          action: 'create',
          file_path: filePath,
          content: value,
        },
      ].filter((x) => x)
      return performActions(actions)
    },
    [basePath, performActions]
  )

  return (
    <>
      <BreadcrumbsBar
        title={title}
        basePath={basePath}
        path={`${path}/create`}
        isCreate={true}
        children={null}
      />
      <div className={classNames(Classes.CARD, Classes.ELEVATION_2)}>
        <FileCreate
          gitFile={gitFile}
          filePath={path}
          onSubmit={authCreateFile}
          onSubmitSuccessful={(newPath) =>
            history.replace(`/${basePath}/${encodeURIComponent(newPath)}`)
          }
          onCancelEditing={() => history.goBack()}
        />
      </div>
      {children}
    </>
  )
}
