import convertSongsToLatex from '../song/utils/convertToLatex'
import React, { useCallback, useState } from 'react'
import Toaster, { errorToast } from '../../components/Toaster'
import { Button, Classes, Dialog, Intent, TextArea } from '@blueprintjs/core'
import { getErrorString } from '../../components/ErrorPage'
import { useAsync } from '../../lib/effects'
import { SetlistItemType } from './utils/parse'
import parse from '../song/utils/parse'
import { Base64 } from 'js-base64'
import { useGitLabApi } from '../../gitlab/gitlabApi'
import { classNames } from 'lib/utils'

const fetchFile = async (file) => {
  const response = await fetch(file)
  if (response.ok) {
    return response
  } else {
    throw response
  }
}
const uploadFile = async (file) => {
  const body = new FormData()
  body.append('file', file)
  const response = await fetch('https://file.io/?expires=1d', {
    method: 'POST',
    body: body,
  })
  const data = await response.json()
  if (response.ok) {
    return data.link
  } else {
    throw data
  }
}

export default function LatexDialogButton({ icon, text, setlist }) {
  const [converting, setConverting] = useState(false)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const { getFile } = useGitLabApi()
  const [{ data: songs }, fetchAllSongs] = useAsync(
    useCallback(async () => {
      const songFiles = await Promise.all(
        setlist
          .filter((x) => x.type === SetlistItemType.SONG)
          .map((song) => getFile(`songs/${song.filepath}.md`))
      )
      return songFiles.map((songFile) => parse(Base64.decode(songFile.content)))
    }, [getFile, setlist])
  )

  const convertToLatex = async () => {
    setConverting(true)
    try {
      await fetchAllSongs()
      setDialogOpen(true)
    } catch (e) {
      errorToast(getErrorString(e), () => convertToLatex())
    }
    setConverting(false)
  }
  const latex = songs && convertSongsToLatex(songs, new Date(), '')
  const [opening, setOpening] = useState(false)
  const openInOverleaf = async () => {
    try {
      setOpening(true)
      const { default: JSZip } = await import('jszip/dist/jszip')
      const zip = new JSZip()
      const clsFile = await fetchFile('/song-sheet.cls')
      zip.file(
        'song-sheet.cls',
        '%\\documentclass[12pt]{article}\n' + (await clsFile.text())
      )
      zip.file('main.tex', latex)
      const notoSansTamilRegularFile = await fetchFile(
        '/fonts/NotoSansTamil-Regular.ttf'
      )
      const notoSansTamilBoldFile = await fetchFile(
        '/fonts/NotoSansTamil-Bold.ttf'
      )
      const notoSansRegularFile = await fetchFile('/fonts/NotoSans-Regular.ttf')
      const notoSansBoldFile = await fetchFile('/fonts/NotoSans-Bold.ttf')
      zip.file(
        'fonts/NotoSansTamil-Regular.ttf',
        await notoSansTamilRegularFile.blob(),
        { binary: true }
      )
      zip.file(
        'fonts/NotoSansTamil-Bold.ttf',
        await notoSansTamilBoldFile.blob(),
        { binary: true }
      )
      zip.file('fonts/NotoSans-Regular.ttf', await notoSansRegularFile.blob(), {
        binary: true,
      })
      zip.file('fonts/NotoSans-Bold.ttf', await notoSansBoldFile.blob(), {
        binary: true,
      })

      const content = await zip.generateAsync({ type: 'blob' })
      const zipFile = new File([content], 'song-sheet.zip')
      const uploadUrl = await uploadFile(zipFile)
      setOpening(false)
      const overleafUrl = `https://www.overleaf.com/docs?engine=xelatex&snip_uri=${uploadUrl}`
      window.open(overleafUrl, '_blank')
    } catch (err) {
      console.error(err)
      Toaster.show({
        icon: 'clipboard',
        intent: Intent.DANGER,
        message: 'Open in Overleaf failed: ' + getErrorString(err),
      })
    }
  }
  return (
    <>
      <Button
        icon={icon}
        text={text}
        loading={converting}
        onClick={convertToLatex}
      />
      <Dialog
        isCloseButtonShown={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        icon="code"
        title="Converted LaTeX"
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        style={{ textAlign: 'center', paddingBottom: 0 }}
      >
        <div className={Classes.DIALOG_BODY}>
          <Button
            intent={Intent.PRIMARY}
            type="submit"
            text="Open in Overleaf"
            loading={opening}
            onClick={openInOverleaf}
          />
          <TextArea
            large={true}
            className={classNames(Classes.CODE_BLOCK, Classes.FILL)}
            onFocus={(e) => {
              e.target.select()
              if (document.queryCommandSupported('copy')) {
                document.execCommand('copy')
                Toaster.show({
                  icon: 'clipboard',
                  intent: Intent.SUCCESS,
                  message: 'Copied to clipboard',
                })
              }
            }}
            readOnly
            value={latex}
            rows={20}
          />
        </div>
      </Dialog>
    </>
  )
}
