import React, { useCallback, useMemo, useState } from 'react'
import { useAsync } from '../lib/effects'
import {
  Button,
  ButtonGroup,
  TextArea,
  InputGroup,
  Tabs,
  Tab,
  Classes,
} from '@blueprintjs/core'
import styles from './form.module.css'
import { getErrorString } from './ErrorPage'
import { handleStringChange } from '../lib/formHandlers'
import { errorToast, successToast } from './Toaster'
import { classNames } from 'lib/utils'
import { GitFile } from 'core/GitFile'

type FileEditProps<T> = {
  value: string
  filePath: string
  gitFile: GitFile<T>
  onSubmit: (v: string, filepath: string) => Promise<any>
  onSubmitSuccessful?: (newPath: string) => void
  onCancelEditing: () => any
}

export default function FileEdit<T>({
  value: initialValue,
  filePath,
  gitFile,
  onSubmit,
  onSubmitSuccessful = () => {},
  onCancelEditing,
}: FileEditProps<T>) {
  const [value, setValue] = useState(initialValue)
  const parsed = useMemo(() => gitFile.parse(value), [gitFile, value])
  const [filepath, setFilePath] = useState(filePath)
  const [{ loading: submitting }, submitData] = useAsync(
    useCallback(
      () =>
        onSubmit(value, filepath)
          .then(() => {
            successToast('Update successful')
            onCancelEditing()
            onSubmitSuccessful(filepath)
          })
          .catch((e) => {
            errorToast(getErrorString(e), e.retry)
          }),
      [onCancelEditing, onSubmitSuccessful, onSubmit, filepath, value]
    )
  )

  return (
    <>
      <div className={styles.pathSaveCancelContainer}>
        <InputGroup
          className={styles.filePathInput}
          onChange={handleStringChange(setFilePath)}
          placeholder="File path"
          value={filepath}
        />
        <ButtonGroup className={styles.saveCancelButton}>
          <Button
            text="Save"
            loading={submitting}
            className={Classes.INTENT_PRIMARY}
            onClick={submitData}
          />
          <Button text="Cancel" onClick={onCancelEditing} />
        </ButtonGroup>
      </div>
      <div className={styles.editContainer}>
        <div className={styles.editArea}>
          <Tabs
            id="EditTabs"
            defaultSelectedTabId={'raw'}
            renderActiveTabPanelOnly={true}
          >
            <Tabs.Expander />
            <Tab
              id="ui"
              title="Editor"
              panel={gitFile.renderForm(parsed, handleStringChange(setValue))}
            />
            <Tab
              id="raw"
              title="Raw"
              panel={
                <TextArea
                  className={classNames(Classes.FILL, styles.editArea)}
                  value={value}
                  rows={32}
                  onChange={handleStringChange(setValue)}
                />
              }
            />
          </Tabs>
        </div>
        <div className={styles.previewArea}>{gitFile.renderFile(parsed)}</div>
      </div>
    </>
  )
}
