import { useAuth, useLogin } from '../gitlab/auth'
import { NavLink } from 'react-router-dom'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Alignment,
  Button,
  Classes,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Navbar,
  Popover,
  Position,
} from '@blueprintjs/core'
import '@blueprintjs/core/lib/css/blueprint.css'
import styles from './layout.module.css'
import useNavigatorOnline from '../lib/effects/useNavigatorOnline'
import { useGitLabApi } from '../gitlab/gitlabApi'
import RelativeTime from './RelativeTime'
import { useAsync } from '../lib/effects'
import { useSetting } from '../lib/settings'
import NoSleep from 'nosleep.js'
import Toaster from '../components/Toaster'
import { classNames } from 'lib/utils'

const tools = [
  { link: '/tools/text2song', text: 'Text to Song' },
  { link: '/tools/batch-add-songs', text: 'Batch Add Songs' },
]

const mainMenu = [
  { link: '/songs', text: 'Songs' },
  { link: '/setlists', text: 'Setlists' },
  { link: '/search', text: <Icon icon={'search'} /> },
]

const utilsMenu = (
  <Menu>
    {tools.map(({ link, text }) => (
      <MenuItem
        key={link}
        tagName={(props) => (
          <NavLink to={link} activeClassName={Classes.ACTIVE} {...props} />
        )}
        text={text}
      />
    ))}
  </Menu>
)

const useWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setWidth])

  return width
}

function useWakeLock() {
  const noSleepRef = useRef(null)
  if (noSleepRef.current === null) {
    noSleepRef.current = new NoSleep()
  }
  const [wakeLock, setWakeLock] = useState(false)
  // useEffect(() => {
  //   if (wakeLock) {
  //     // Enable wake lock.
  //     // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
  //     noSleepRef.current.enable()
  //     Toaster.show({ message: 'WakeLock enabled' })
  //     return () => {
  //       noSleepRef.current.disable()
  //       Toaster.show({ message: 'WakeLock disabled' })
  //     }
  //   }
  // }, [wakeLock])
  const setWakeLockWithEffect = useCallback(
    (fn) => {
      setWakeLock(fn)
      if (typeof fn === 'function' ? fn(wakeLock) : fn) {
        noSleepRef.current.enable()
        Toaster.show({ message: 'WakeLock enabled' })
      } else {
        noSleepRef.current.disable()
        Toaster.show({ message: 'WakeLock disabled' })
      }
    },
    [wakeLock, setWakeLock]
  )
  return [wakeLock, setWakeLockWithEffect]
}

function useTheme() {
  const [theme, setTheme] = useSetting('theme', 'light')

  useEffect(() => {
    if (theme === 'dark') {
      document.body.className = Classes.DARK
    }
    if (theme === 'light') {
      document.body.className = ''
    }
  }, [theme])
  const toggleTheme = () =>
    setTheme((theme) => (theme === 'light' ? 'dark' : 'light'))
  return [theme, toggleTheme]
}

const UserAvatar = ({ user, logout }) => {
  const width = useWidth()
  const [theme, toggleTheme] = useTheme()

  return (
    <Popover
      content={
        <>
          <div className={classNames(Classes.TEXT_LARGE, Classes.HEADING)}>
            {user.name}
          </div>
          <div className={Classes.TEXT_MUTED}>{user.username}</div>
          <div className={Classes.TEXT_SMALL}>{user.email}</div>
          <div style={{ height: '1em' }} />
          <Button
            icon={theme === 'light' ? 'moon' : 'flash'}
            onClick={toggleTheme}
            minimal
          >
            {theme === 'light' ? 'Dark' : 'Light'} Mode
          </Button>
          <div style={{ height: '1em' }} />
          <Button
            intent={Intent.DANGER}
            icon="log-out"
            onClick={logout}
            text="Logout"
          />
        </>
      }
      position={Position.BOTTOM}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
    >
      <div className={classNames(Classes.BUTTON, Classes.MINIMAL)}>
        <img
          style={{ borderRadius: '50%' }}
          width={width < 400 ? 18 : 24}
          src={user.avatar_url}
          alt={user.username}
        />
      </div>
    </Popover>
  )
}

const LatestCommit = () => {
  const { latestCommit, fetchLatestCommit } = useGitLabApi()
  const [{ loading }, updateLatestCommit] = useAsync(fetchLatestCommit)
  const width = useWidth()

  if (!latestCommit) {
    return null
  }
  return (
    <Popover
      content={
        <div style={{ maxWidth: `${width - 50}px` }}>
          <div className={Classes.HEADING}>
            <b>{latestCommit.title}</b>
          </div>
          <div className={classNames(Classes.TEXT_OVERFLOW_ELLIPSIS)}>
            <code className={Classes.TEXT_MUTED}>{latestCommit.id}</code>
          </div>
          <div>
            <i>By</i> <b>{latestCommit.author_name}</b>
          </div>
          <div className={classNames(Classes.TEXT_SMALL, Classes.TEXT_MUTED)}>
            <i>
              <RelativeTime time={latestCommit.created_at} />
            </i>
          </div>
          <div style={{ height: '1em' }} />
          <Button
            icon="refresh"
            intent={Intent.PRIMARY}
            onClick={updateLatestCommit}
          >
            Refresh
          </Button>
        </div>
      }
      position={Position.BOTTOM}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
    >
      <Button
        minimal
        loading={loading}
        icon={
          <Icon
            icon="git-commit"
            style={{
              // backgroundColor: `#${latestCommit.short_id.substring(0, 6)}`,
              color: `#${latestCommit.short_id.substring(0, 6)}`,
            }}
          />
        }
      >
        {width > 400 && <code>{latestCommit.short_id}</code>}
      </Button>
    </Popover>
  )
}

export default function Layout({ children }) {
  const { isLoggedIn, user, logout } = useAuth()
  const { isOnline } = useNavigatorOnline()
  const [wakeLock, setWakeLock] = useWakeLock(false)
  const { loginRedirect, redirecting } = useLogin()

  return (
    <div className={classNames(styles.container)}>
      <Navbar fixedToTop={true}>
        <Navbar.Group align={Alignment.LEFT}>
          {mainMenu.map((menu) => (
            <NavLink
              key={menu.link}
              to={menu.link}
              className={classNames(Classes.BUTTON, Classes.MINIMAL)}
              activeClassName={Classes.ACTIVE}
            >
              {menu.text}
            </NavLink>
          ))}
          <Popover content={utilsMenu} position={Position.BOTTOM}>
            <Button minimal icon="briefcase" />
          </Popover>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {!isOnline && <Button minimal icon="offline" />}
          <Button
            icon="eye-open"
            active={wakeLock}
            onClick={() => setWakeLock((w) => !w)}
            minimal
          />
          <LatestCommit />
          {!isLoggedIn && (
            <Button minimal onClick={loginRedirect} loading={redirecting}>
              <Icon icon="log-in" />
            </Button>
          )}
          {isLoggedIn && <UserAvatar user={user} logout={logout} />}
        </Navbar.Group>
      </Navbar>
      <div className={styles.children}>{children}</div>
    </div>
  )
}
