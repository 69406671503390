import React, { useEffect, useState } from 'react'
import { formatDistanceToNow } from 'date-fns'

export default function RelativeTime({ time }) {
  const [, setCurrentTime] = useState(() => Date.now())
  useEffect(() => {
    if (time) {
      const timer = setInterval(() => {
        setCurrentTime(Date.now())
      }, 10000)
      return () => clearInterval(timer)
    }
  }, [time])

  if (!time) {
    return null
  }

  const d = new Date(time)

  return (
    <span title={d.toLocaleString()}>
      {formatDistanceToNow(d, { addSuffix: true })}
    </span>
  )
}
