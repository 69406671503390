export type Song = {
  meta: SongMeta
  lyrics: SongLyrics
}

export type SongLyrics = SongStanza[]
export type SongMeta = {
  title?: string
  subtitle?: string
  transpose?: string
  key?: string
  time?: string
  tempo?: number
  links?: { url: string; title: string }[]
}

export enum SongStanzaType {
  Chorus = 'chorus',
  Verse = 'verse',
  Bridge = 'bridge',
  Tag = 'tag',
  Intro = 'intro',
  Outro = 'outro',
  Interlude = 'interlude',
}

export interface SongStanza {
  paragraphs: SongParagraph[]
  type: SongStanzaType
  number?: number
}

export interface SongParagraph {
  lines: SongLine[]
}

export interface SongLine {
  parts: SongLinePart[]
}

export interface SongLinePart {
  chord?: string
  text: string
}
