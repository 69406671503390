import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useLocalStorageState } from '../lib/effects'
import {
  getGroupMember,
  getProjectMember,
  getUser,
  getProject,
} from './gitlabRawApi'
import config from '../config'
import ErrorPage, { getErrorString } from '../components/ErrorPage'
import AppSpinner from '../components/AppSpinner'
import Toaster from '../components/Toaster'
import { useAsync } from '../lib/effects'
import { Redirect, useLocation } from 'react-router'
import { Intent } from '@blueprintjs/core'

export const AuthContext = React.createContext({
  token: null,
})

export const useAuth = () => {
  const { token, projectId, user, login, logout } = useContext(AuthContext)
  return {
    token,
    projectId,
    user,
    isLoggedIn: !!token && !!user,
    login,
    logout,
  }
}

const stateStorage = {
  setState(v) {
    if (v === null) {
      sessionStorage.removeItem('state')
    } else {
      sessionStorage.setItem('state', JSON.stringify(v))
    }
  },

  getState() {
    return JSON.parse(sessionStorage.getItem('state'))
  },
}

export const useLogin = () => {
  const [redirecting, setRedirecting] = useState(false)

  function makeid(n = 10) {
    let text = ''
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < n; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  }

  const loginRedirect = () => {
    setRedirecting(true)
    const client_id = config.gitlab_client_id
    const redirect_uri = config.gitlab_redirect_uri
    const state = makeid()
    stateStorage.setState({
      state,
    })
    window.location = `https://gitlab.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&state=${state}`
  }

  return {
    loginRedirect,
    redirecting,
  }
}

const useLoginCallback = (login) => {
  const location = useLocation()
  const [{ loading: loggingIn, error }, doLogin] = useAsync(
    useCallback(
      async (token, statesEqual) => {
        try {
          if (!statesEqual) {
            throw new Error('States not equal')
          }
          await login(token)
          Toaster.show({
            icon: 'tick',
            intent: Intent.SUCCESS,
            message: 'Logged in',
          })
        } catch (err) {
          Toaster.show({
            icon: 'warning-sign',
            intent: Intent.DANGER,
            message: 'LoginPage failed: ' + getErrorString(err),
          })
          throw err
        }
      },
      [login]
    )
  )

  const params = new URLSearchParams(location.hash.replace(/^#/, '?'))
  const access_token = params.get('access_token')
  const state = params.get('state')
  const isCallback = access_token && state

  useEffect(() => {
    if (isCallback) {
      const savedState = stateStorage.getState() || {}
      stateStorage.setState(null)
      doLogin(access_token, state === savedState.state)
    }
  }, [doLogin, isCallback, access_token, state])

  return { isCallback, loggingIn, error }
}

export function AuthProvider(props) {
  const [token, setToken] = useLocalStorageState('token')
  const [user, setUser] = useLocalStorageState('user')
  const [project, setProject] = useLocalStorageState('project')
  const projectId = config.gitlab_project_id
  const login = useCallback(
    async (token) => {
      const user = await getUser(token)()
      try {
        user.member = await getGroupMember(
          projectId,
          token
        )(user.id).catch(() => getProjectMember(projectId, token)(user.id))
        setToken(token)
        setUser(user)
      } catch (e) {
        throw new Error('User is not a member of the Project')
      }
    },
    [projectId, setToken, setUser]
  )
  const { isCallback, loggingIn, error } = useLoginCallback(login)

  const logout = useCallback(() => {
    setToken(null)
    setUser(null)
    setProject(null)
  }, [setProject, setToken, setUser])

  const [{ loading: projectLoading, error: projectError }] = useAsync(
    useCallback(async () => {
      if (isCallback) return
      const project = await getProject(projectId, token)()
      setProject(project)
    }, [projectId, setProject, token, isCallback]),
    true
  )

  if (
    !project &&
    projectError &&
    projectError.message !== '404 Project Not Found'
  ) {
    return (
      <ErrorPage error={`Unable to fetch project: ${projectError.message}`} />
    )
  }

  if (!project && projectLoading) {
    return <AppSpinner>Fetching project</AppSpinner>
  }

  if (loggingIn) {
    return <AppSpinner>Logging In</AppSpinner>
  }

  if (error) {
    return <Redirect to="/" />
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        projectId: project && project.id,
        user,
        // login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
