import {
  Intent,
  Position,
  Toaster as BlueprintToaster,
} from '@blueprintjs/core'

const Toaster = BlueprintToaster.create({ position: Position.BOTTOM })

export default Toaster

export const successToast = (message) =>
  Toaster.show({
    icon: 'tick',
    intent: Intent.SUCCESS,
    message: message,
  })

export const errorToast = (message, onRetry) =>
  Toaster.show({
    icon: 'warning-sign',
    intent: Intent.DANGER,
    action: onRetry && {
      onClick: () => onRetry(),
      text: 'Retry',
    },
    message: message,
  })
