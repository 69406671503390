import { useEffect, useRef } from 'react'

function useTitle(title, retainOnUnmount = false) {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    const initialTitle = defaultTitle.current
    document.title = title

    return () => {
      if (!retainOnUnmount) document.title = initialTitle
    }
  }, [title, retainOnUnmount])
}

export default useTitle
