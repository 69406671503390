import { useEffect, useState } from 'react'

function useNavigatorOnline() {
  let [value, setValue] = useState(window.navigator.onLine)

  useEffect(() => {
    function handleOnlineStatus(event) {
      setValue(window.navigator.onLine)
    }

    window.addEventListener('online', handleOnlineStatus)
    window.addEventListener('offline', handleOnlineStatus)

    return () => {
      window.removeEventListener('online', handleOnlineStatus)
      window.removeEventListener('offline', handleOnlineStatus)
    }
  }, [])

  let isOnline = value === true
  let isOffline = value === false

  return { isOnline, isOffline }
}

export default useNavigatorOnline
