import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './gitlab/auth'
import Routes from './Routes'
import { GitLabApiProvider } from './gitlab/gitlabApi'
import { SettingsProvider } from './lib/settings'
import ErrorBoundary from './components/ErrorBoundary'

function App() {
  return (
    <ErrorBoundary>
      <SettingsProvider>
        <Router>
          <AuthProvider>
            <GitLabApiProvider>
              <Routes />
            </GitLabApiProvider>
          </AuthProvider>
        </Router>
      </SettingsProvider>
    </ErrorBoundary>
  )
}

export default App
