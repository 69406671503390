import React, { useState } from 'react'
import styles from './tools.module.css'
import { Button, Card, Intent, TextArea } from '@blueprintjs/core'
import { useLocalStorageState } from '../../lib/effects'
import Toaster from '../../components/Toaster'
import Song from '../../core/song/SongRender'
import stringify from '../../core/song/utils/stringify'
import {
  SongLinePart,
  SongLyrics,
  SongParagraph,
  SongStanzaType,
} from '../../core/song/types'
import parseSong from 'core/song/utils/parse'

function chunk<T>(list: T[], chunkSize: number) {
  return new Array(Math.ceil(list.length / chunkSize))
    .fill(1)
    .map((_, i) => list.slice(i * chunkSize, (i + 1) * chunkSize))
}

function substringWithPadding(str: string, start: number, end: number): string {
  const substr = str.substring(start, end)
  return substr + ' '.repeat(Math.max(0, end - start - substr.length))
}

function chordParse(chordLine: string, lyricsLine: string): SongLinePart[] {
  let match
  const chords: [string, number][] = []
  const chordPlusLyrics: SongLinePart[] = []
  const re = /(^\s+|\S+)/g
  while ((match = re.exec(chordLine)) !== null) {
    chords.push([match[1], match.index])
  }
  chords.push(['', lyricsLine.length])
  for (let i = 0; i < chords.length - 1; ++i) {
    const [chord, index] = chords[i]
    chordPlusLyrics.push({
      chord: chord.trim(),
      text: substringWithPadding(lyricsLine, index, chords[i + 1][1]),
    })
  }
  return chordPlusLyrics
}

export function convertText2Song(songStr: string, hasChord: boolean): string {
  const stanzas = songStr.split(
    /\n(?=\[.+]|(?:Verse|Chorus|Interlude|Tag|Outro)\s?\d*)/gi
  )
  console.log(stanzas)
  const lyrics: SongLyrics = stanzas
    .map((stanza) => {
      const lines = stanza.trim().split(/\n/)
      const line1 = lines.shift()!
      const paragraphs: SongParagraph[] = lines
        .join('\n')
        .split(/\n\s*\n/g)
        .map((paragraph) => {
          if (hasChord) {
            return {
              lines: chunk(paragraph.split('\n'), 2).map(
                ([chordLine, lyricsLine = '']) => {
                  return {
                    parts: chordParse(chordLine, lyricsLine),
                  }
                }
              ),
            }
          } else {
            return {
              lines: paragraph.split('\n').map((line) => {
                return {
                  parts: [{ chord: '', text: line.trim() }],
                }
              }),
            }
          }
        })
      if (/^\[?chorus\s*\d*]?/i.test(line1)) {
        // chorus
        const matches = /^\[?chorus\s*(\d*)]?/i.exec(line1)
        return {
          paragraphs: paragraphs,
          type: SongStanzaType.Chorus,
          number: !matches ? 0 : parseInt(matches[1]),
        }
      } else if (/^\[?verse\s*(\d*|\*)]?/i.test(line1)) {
        // verse
        const matches = /^\[?verse\s*(\d*|\*)]?/i.exec(line1)
        console.log(matches)
        return {
          paragraphs: paragraphs,
          type: SongStanzaType.Verse,
          number: !matches ? 0 : matches[1] === '*' ? -1 : parseInt(matches[1]),
        }
      } else if (/^\[?bridge\s*\d*]?/i.test(line1)) {
        // bridge
        // TODO: update bridge similar to verse
        const matches = /^\[?bridge\s*(\d*)]?/i.exec(line1)
        return {
          paragraphs: paragraphs,
          type: SongStanzaType.Bridge,
          number: !matches ? 0 : parseInt(matches[1]),
        }
      } else if (/^\[?tag\s*\d*]?/i.test(line1)) {
        // tag
        // TODO: update bridge similar to verse
        const matches = /^\[?tag\s*(\d*)]?/i.exec(line1)
        return {
          paragraphs: paragraphs,
          type: SongStanzaType.Tag,
          number: !matches ? 0 : parseInt(matches[1]),
        }
      } else if (/^\[?intro\s*\d*]?/i.test(line1)) {
        // intro
        // TODO: update bridge similar to verse
        const matches = /^\[?intro\s*(\d*)]?/i.exec(line1)
        return {
          paragraphs: paragraphs,
          type: SongStanzaType.Intro,
          number: !matches ? 0 : parseInt(matches[1]),
        }
      } else if (/^\[?outro\s*\d*]?/i.test(line1)) {
        // outro
        // TODO: update bridge similar to verse
        const matches = /^\[?outro\s*(\d*)]?/i.exec(line1)
        return {
          paragraphs: paragraphs,
          type: SongStanzaType.Outro,
          number: !matches ? 0 : parseInt(matches[1]),
        }
      } else if (/^\[?interlude\s*\d*]?/i.test(line1)) {
        // interlude
        // TODO: update bridge similar to verse
        const matches = /^\[?interlude\s*(\d*)]?/i.exec(line1)
        return {
          paragraphs: paragraphs,
          type: SongStanzaType.Interlude,
          number: !matches ? 0 : parseInt(matches[1]),
        }
      } else {
        return null
      }
    })
    .filter(<T extends {}>(x: T | null): x is T => x !== null)

  const title: string =
    lyrics.map(
      (stanza) =>
        stanza.paragraphs.map(
          (paragraph) =>
            paragraph.lines.map((line) =>
              line.parts
                .map((part) => part.text)
                .join('')
                .trim()
            )[0]
        )[0]
    )[0] || ''
  const song = {
    meta: {
      title: title,
    },
    lyrics: lyrics,
  }
  // @ts-ignore
  return stringify(song)
}

export default function Text2Song() {
  const [value, setValue] = useLocalStorageState('text2song', '')
  const [preview, setPreview] = useState(false)
  const [hasChord, setHasChord] = useState(false)

  const convertedText = convertText2Song(value, hasChord)

  const copyToClipboard = async () => {
    // @ts-ignore
    await navigator.clipboard.writeText(convertedText)
    Toaster.show({
      icon: 'clipboard',
      intent: Intent.SUCCESS,
      message: 'Copied to Clipboard.',
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.tools}>
        <Button onClick={copyToClipboard} icon="clipboard">
          Copy To Clipboard
        </Button>
        <Button onClick={() => setPreview((p) => !p)} active={preview}>
          Preview
        </Button>
        <Button onClick={() => setHasChord((c) => !c)} active={hasChord}>
          Chord
        </Button>
      </div>
      <TextArea
        className={styles.input}
        placeholder="Enter txt here..."
        rows={40}
        large={true}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <Card className={styles.output}>
        {preview ? (
          <Song song={parseSong(convertedText)} />
        ) : (
          <TextArea
            className={styles.input}
            style={{ width: '100%' }}
            rows={40}
            large
            readOnly
            value={convertedText}
          />
        )}
      </Card>
    </div>
  )
}
