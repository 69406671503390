import React from 'react'
import ReactDOM from 'react-dom'
import './app.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Toaster from './components/Toaster'
import { Intent } from '@blueprintjs/core'

function render() {
  ReactDOM.render(<App />, document.getElementById('root'))
}

render()

if (module.hot) {
  module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: async (registration) => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', async (event) => {
        // @ts-ignore
        if (event.target?.state === 'activated') {
          await registration.unregister()
          window.location.reload()
        }
      })
      Toaster.show({
        icon: 'automatic-updates',
        intent: Intent.PRIMARY,
        message: 'App update available.',
        timeout: 0,
        action: {
          text: 'Update Now',
          onClick: () => {
            waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
          },
        },
      })
    }
  },
})
