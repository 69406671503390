import * as Classes from '@blueprintjs/core/lib/esm/common/classes'
import React from 'react'
import { Breadcrumbs } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { classNames } from 'lib/utils'
import { capitalizeFirstLetter } from '../core/helpers'

const getBreadcrumbItems = ({ title, path, basePath, isFile, isCreate }) => {
  const breadcrumbs = [{ href: `${basePath}`, text: title }]
  if (!path) return breadcrumbs
  const splitPaths = path.split('/').filter((x) => x)
  let fileFragment = null
  if (isFile) {
    fileFragment = {
      href: `${basePath}/${encodeURIComponent(splitPaths.join('/'))}`,
      icon: 'document',
      text: splitPaths.pop(),
    }
  }
  if (isCreate) {
    const pathSegment = splitPaths.pop()
    fileFragment = {
      href: `${basePath}/${pathSegment}`,
      text: capitalizeFirstLetter(pathSegment),
    }
  }
  const pathFragments = []
  for (const fragment of splitPaths) {
    pathFragments.push(fragment)
    const crumb = {
      href: `${basePath}?path=${encodeURIComponent(pathFragments.join('/'))}`,
      icon: 'folder-close',
      text: fragment,
      tagName: Link,
      to: `${basePath}?path=${encodeURIComponent(pathFragments.join('/'))}`,
    }
    breadcrumbs.push(crumb)
  }
  if (fileFragment) {
    breadcrumbs.push(fileFragment)
  }
  return breadcrumbs
}

const renderBreadcrumb = (props) => {
  const classes = classNames(
    Classes.BREADCRUMB,
    {
      [Classes.BREADCRUMB_CURRENT]: props.current,
      [Classes.DISABLED]: props.disabled,
    },
    props.className
  )
  if (props.href == null && props.onClick == null) {
    return (
      <span className={classes}>
        {props.text}
        {props.children}
      </span>
    )
  }
  return (
    <Link
      className={classes}
      to={props.href}
      onClick={props.disabled ? null : props.onClick}
      tabIndex={props.disabled ? null : 0}
      target={props.target}
    >
      {props.text}
      {props.children}
    </Link>
  )
}

const renderCurrentBreadcrumb = (props) => {
  return renderBreadcrumb({ ...props, current: true })
}

export default function BreadcrumbsBar({
  title,
  basePath,
  path,
  isFile = false,
  isCreate = false,
  children,
}) {
  const breadcrumbItems = getBreadcrumbItems({
    title,
    path,
    basePath: '/' + basePath,
    isFile,
    isCreate,
  })
  return (
    <div
      className={classNames(Classes.CARD, Classes.ELEVATION_2)}
      style={{ paddingTop: 8, paddingBottom: 8, display: 'flex' }}
    >
      <div style={{ flex: 1 }}>
        <Breadcrumbs
          items={breadcrumbItems}
          breadcrumbRenderer={renderBreadcrumb}
          currentBreadcrumbRenderer={renderCurrentBreadcrumb}
        />
      </div>
      {children}
    </div>
  )
}
