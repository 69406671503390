import React, { useEffect } from 'react'
import { NonIdealState } from '@blueprintjs/core'

export function getErrorString(error) {
  if (!error) {
    return null
  }
  if (typeof error === 'string') {
    return error
  }
  if (error.message) {
    return error.message
  }
  if (error.error) {
    return error.error
  }
  if (error.errors) {
    return error.errors.map((e) => e.message).join('. ')
  }
}

function ErrorPage({ error, action }) {
  useEffect(() => {
    console.warn(error)
  }, [error])
  return (
    <NonIdealState
      icon={'error'}
      title={getErrorString(error)}
      description={undefined}
      action={action}
    />
  )
}

export default ErrorPage
