import React, { ReactElement } from 'react'
import FileDetailsPage from '../components/page/FileDetailsPage'
import { NavLink, useParams, useRouteMatch } from 'react-router-dom'
import { ButtonGroup, Classes } from '@blueprintjs/core'
import { useGitLabFile } from '../components/page/helpers'
import { parseFrontmatter } from '../core/helpers'
import { classNames } from 'lib/utils'
import { Setlist, SetlistItemType } from 'core/setlist/utils/parse'
import SetlistFile from 'core/setlist/SetlistFile'
import SongFile from 'core/song/SongFile'
import FileCreatePage from 'components/page/FileCreatePage'
import FileListPage from 'components/page/FileListPage'

function SetNavigatorBarItem({
  index,
  songPath,
}: {
  index: number
  songPath: string
}) {
  const { data } = useGitLabFile('songs/' + songPath + '.md')
  const [meta] = data ? parseFrontmatter(data) : []
  const { url } = useRouteMatch()
  return (
    <NavLink
      className={classNames(Classes.BUTTON, Classes.TEXT_OVERFLOW_ELLIPSIS)}
      style={{ display: 'inline-block' }}
      to={`${url}/${encodeURIComponent(songPath)}`}
      activeClassName={Classes.ACTIVE}
    >
      {index === null ? '' : index + '. '}
      {meta && meta.title ? meta.title : songPath}
    </NavLink>
  )
}

function SetNavigatorBar({ setlist }: { setlist: Setlist }) {
  const { items } = setlist
  return (
    <ButtonGroup style={{ textAlign: 'center' }} fill>
      {items
        .filter((item) => item.type === SetlistItemType.SONG)
        .map((song, i) => (
          <SetNavigatorBarItem
            key={i}
            // @ts-ignore
            index={song.index}
            // @ts-ignore
            songPath={song.filepath}
          />
        ))}
    </ButtonGroup>
  )
}

export function SetDetailsPage() {
  const match = useRouteMatch()
  const isSetListPage = useRouteMatch({ path: `${match.path}`, exact: true })
  const isSongDetailsPage = useRouteMatch<{ songId: string }>(
    `${match.path}/:songId`
  )
  const { id } = useParams<{ id: string }>()
  const SetlistFileClone = Object.assign(
    Object.create(SetlistFile.constructor.prototype),
    SetlistFile
  )
  SetlistFileClone.renderFile = (set: Setlist): ReactElement<any, any> => {
    if (isSetListPage) return SetlistFile.renderFile(set)
    else if (isSongDetailsPage) return <SetNavigatorBar setlist={set} />
    else return <></>
  }
  return (
    <FileDetailsPage path={id} gitFile={SetlistFileClone}>
      {isSongDetailsPage && (
        <FileDetailsPage
          path={isSongDetailsPage.params.songId}
          gitFile={SongFile}
        />
      )}
    </FileDetailsPage>
  )
}

export function SetCreatePage() {
  return <FileCreatePage gitFile={SetlistFile} />
}

export function SetListPage() {
  return <FileListPage gitFile={SetlistFile} />
}
