/* eslint-disable */
// https://github.com/martijnversluis/ChordJS

const A = 'A'.charCodeAt(0)
const G = 'G'.charCodeAt(0)

type Base = string
type Modifier = '#' | 'b' | string | null

const keyChange = (key: string, delta: number) => {
  let charCode
  charCode = key.toUpperCase().charCodeAt(0)
  charCode += delta

  if (charCode > G) {
    charCode = A
  }

  if (charCode < A) {
    charCode = G
  }

  return String.fromCharCode(charCode)
}

const keyUp = (key: string) => keyChange(key, 1)

const keyDown = (key: string) => keyChange(key, -1)

function normalize(base: Base, modifier: Modifier): [Base, Modifier] {
  if (modifier === '#' && /^[BE]$/.test(base)) {
    return [keyUp(base), null]
  }

  if (modifier === 'b' && /^[CF]$/.test(base)) {
    return [keyDown(base), null]
  }

  return [base, modifier]
}

function useModifier(
  base: Base,
  modifier: Modifier,
  newModifier: Modifier
): [Base, Modifier] {
  if (modifier && modifier !== newModifier) {
    if (modifier === '#') {
      return [keyUp(base), 'b']
    }

    if (modifier === 'b') {
      return [keyDown(base), '#']
    }

    throw new Error(`Unexpected modifier ${modifier}`)
  }

  return [base, modifier]
}

function repeatProcessor(
  base: Base,
  modifier: Modifier,
  processor: (base: Base, modifier: Modifier, arg?: any) => [Base, Modifier],
  amount: number
): [Base, Modifier] {
  let [processedBase, processedModifier] = [base, modifier]

  for (let i = 0; i < amount; i += 1) {
    ;[processedBase, processedModifier] = processor(
      processedBase,
      processedModifier
    )
  }

  return [processedBase, processedModifier]
}

function transposeUp(base: Base, modifier: Modifier): [Base, Modifier] {
  const [normalizedBase, normalizedModifier] = normalize(base, modifier)

  if (normalizedModifier === 'b') {
    return [normalizedBase, null]
  }

  if (normalizedModifier === '#') {
    return [keyUp(normalizedBase), null]
  }

  if (/^[BE]$/.test(normalizedBase)) {
    return [keyUp(normalizedBase), null]
  }

  return [normalizedBase, '#']
}

function transposeDown(base: Base, modifier: Modifier): [Base, Modifier] {
  const [normalizedBase, normalizedModifier] = normalize(base, modifier)

  if (normalizedModifier === 'b') {
    return [keyDown(normalizedBase), null]
  }

  if (normalizedModifier === '#') {
    return [normalizedBase, null]
  }

  if (/^[CF]$/.test(normalizedBase)) {
    return [keyDown(normalizedBase), null]
  }

  return [normalizedBase, 'b']
}

const transpose = (base: Base, modifier: Modifier, delta: number) => {
  let [newBase, newModifier] = [base, modifier]

  if (delta < 0) {
    ;[newBase, newModifier] = repeatProcessor(
      base,
      modifier,
      transposeDown,
      Math.abs(delta)
    )
  } else if (delta > 0) {
    ;[newBase, newModifier] = repeatProcessor(
      base,
      modifier,
      transposeUp,
      delta
    )
  }

  return useModifier(newBase, newModifier, modifier)
}

function processChord(
  sourceChord: Chord,
  processor: (base: Base, modifier: Modifier, arg?: any) => [Base, Modifier],
  processorArg?: any
): Chord {
  const chord = sourceChord.clone()
  ;[chord.base, chord.modifier] = processor(
    sourceChord.base,
    sourceChord.modifier,
    processorArg
  )

  if (sourceChord.bassBase) {
    ;[chord.bassBase, chord.bassModifier] = processor(
      sourceChord.bassBase,
      sourceChord.bassModifier,
      processorArg
    )
  }

  return chord
}

class Chord {
  base: Base
  modifier: Modifier
  suffix: string
  bassBase: Base
  bassModifier: Modifier

  static parse(chordString: string) {
    const chordRegex = /([A-G])([#b])?([^/\s]*)(\/([A-G])([#b])?)?/i
    const parts = chordRegex.exec(chordString)

    if (parts) {
      const [, base, modifier, suffix, , bassBase, bassModifier] = parts
      return new Chord(base, modifier, suffix, bassBase, bassModifier)
    }

    return null
  }

  constructor(
    base: Base,
    modifier: Modifier,
    suffix: string,
    bassBase: Base,
    bassModifier: Modifier
  ) {
    this.base = base // || null;
    this.modifier = modifier || null
    this.suffix = suffix // || null;
    this.bassBase = bassBase // || null;
    this.bassModifier = bassModifier || null
  }

  clone() {
    const { base, modifier, suffix, bassBase, bassModifier } = this
    return new Chord(base, modifier, suffix, bassBase, bassModifier)
  }

  normalize() {
    return processChord(this, normalize)
  }

  useModifier(newModifier: Modifier) {
    return processChord(this, useModifier, newModifier)
  }

  transpose(delta: number) {
    return processChord(this, transpose, delta)
  }

  toString() {
    const chordString = this.base + (this.modifier || '') + (this.suffix || '')

    if (this.bassBase) {
      return `${chordString}/${this.bassBase}${this.bassModifier || ''}`
    }

    return chordString
  }
}

export default Chord
