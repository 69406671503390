import React, { useCallback, useContext } from 'react'
import { useLocalStorageState } from './effects'

export const SettingsContext = React.createContext({})

const getOrElse = (map, key, defaultValue) => {
  return map && key in map ? map[key] : defaultValue
}

export const useSetting = (field, defaultValue = null) => {
  const { settings, setSettings } = useContext(SettingsContext)
  const setSetting = useCallback(
    (value) => {
      setSettings((settings) => ({
        ...settings,
        [field]:
          typeof value === 'function'
            ? value(getOrElse(settings, field, defaultValue))
            : value,
      }))
    },
    [field, defaultValue, setSettings]
  )
  const setting = getOrElse(settings, field, defaultValue)
  return [setting, setSetting]
}

export function SettingsProvider(props) {
  const [settings, setSettings] = useLocalStorageState('settings')

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  )
}
