import { useTitle } from '../../lib/effects'
import React from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import ErrorPage from '../ErrorPage'
import AppSpinner from '../AppSpinner'
import {
  Button,
  ButtonGroup,
  Classes,
  Icon,
  Menu,
  Text,
  Intent,
} from '@blueprintjs/core'
import { stripPrefix, classNames } from '../../lib/utils'
import BreadcrumbsBar from '../BreadcrumbsBar'
import FileCreateButton from '../FileCreateButton'
import { IconNames } from '@blueprintjs/icons'
import { useSetting } from '../../lib/settings'
import { useGitLabFile, useGitLabTree } from './helpers'
import { parseFrontmatter } from 'core/helpers'
import { GitFile } from 'core/GitFile'

const BLOB = 'blob'
const TREE = 'tree'

function renderIcon(ext: string) {
  switch (ext) {
    case 'songs':
      return <Icon icon={IconNames.MUSIC} />
    case 'setlists':
      return <Icon icon={IconNames.LIST} />
    default:
      return <Icon icon={IconNames.DOCUMENT} />
  }
}

type FileListItemProps = {
  type: string
  basePath: string
  path: string
}

export function FileListItem({ type, basePath, path }: FileListItemProps) {
  const { url } = useRouteMatch()
  const relativePath = stripPrefix(basePath + '/', path).replace(/.md$/, '')
  const name = relativePath.split('/').pop()
  const { data: contents } = useGitLabFile(type === BLOB ? path : '')
  if (type === TREE) {
    return (
      <Link
        className={Classes.MENU_ITEM}
        to={`${url}?path=${encodeURIComponent(relativePath)}`}
      >
        <Icon icon={IconNames.FOLDER_CLOSE} />
        <Text ellipsize className={Classes.FILL}>
          {name}
        </Text>
      </Link>
    )
  } else if (type === BLOB) {
    const renderTitleSubtitle = () => {
      const [fileMeta] = parseFrontmatter(contents)
      return (
        <>
          {fileMeta.title && (
            <Text className={Classes.FILL} ellipsize>
              {fileMeta.title}
            </Text>
          )}
          <Text
            className={classNames(
              Classes.FILL,
              Classes.TEXT_MUTED,
              Classes.TEXT_SMALL
            )}
            ellipsize
          >
            {fileMeta.subtitle || name}
          </Text>
        </>
      )
    }
    return (
      <Link
        className={Classes.MENU_ITEM}
        to={`${url}/${encodeURIComponent(relativePath)}`}
      >
        {renderIcon(basePath.replace('/', ''))}
        <div style={{ flex: 1, minWidth: 0 }}>
          {contents ? (
            renderTitleSubtitle()
          ) : (
            <Text ellipsize className={Classes.FILL}>
              {name}
            </Text>
          )}
        </div>
      </Link>
    )
  } else {
    return <>{path}</>
  }
}

type GitLabTreeItem = {
  type: 'blob' | 'tree'
  path: string
}

type FileListProps = {
  basePath: string
  path: string
  treeView: boolean
}

function FileList({ path, basePath, treeView }: FileListProps) {
  const { tree, error, loading, reFetchData } = useGitLabTree(
    basePath + '/' + path,
    treeView
  )

  if (loading) {
    return <AppSpinner />
  }

  if (error) {
    return (
      <ErrorPage
        error={error}
        action={<Button text="Retry" onClick={reFetchData} />}
      />
    )
  }

  const files = tree.filter(
    (file: GitLabTreeItem) => file.type === BLOB || file.type === TREE
  )

  return (
    <Menu>
      {files.map((file: GitLabTreeItem) => (
        <li key={file.path}>
          <FileListItem type={file.type} basePath={basePath} path={file.path} />
        </li>
      ))}
    </Menu>
  )
}

type FileListPageProps<T> = {
  gitFile: GitFile<T>
}

export default function FileListPage<T>({ gitFile }: FileListPageProps<T>) {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const path = params.get('path') || ''
  const [treeView, setTreeView] = useSetting('tree_view', true)
  const title = gitFile.title
  const basePath = gitFile.basePath

  useTitle(title)

  return (
    <>
      <BreadcrumbsBar
        title={title}
        basePath={basePath}
        path={path}
        isFile={false}
      >
        <ButtonGroup>
          <Button
            icon="diagram-tree"
            title="Tree view"
            active={treeView}
            minimal={true}
            outlined={true}
            onClick={() => setTreeView(!treeView)}
          />
          <FileCreateButton
            basePath={basePath}
            path={path}
            intent={Intent.PRIMARY}
            minimal={true}
            outlined={true}
          />
        </ButtonGroup>
      </BreadcrumbsBar>
      <div className={classNames(Classes.CARD, Classes.ELEVATION_2)}>
        <FileList basePath={basePath} path={path} treeView={treeView} />
      </div>
    </>
  )
}
