import { useAsync } from '../lib/effects'
import { errorToast, successToast } from './Toaster'
import { Button, Classes, Icon, Intent, Popover } from '@blueprintjs/core'
import { getErrorString } from './ErrorPage'
import React, { useCallback } from 'react'

type FileDeleteButtonProps = {
  fileName: string
  onDelete: () => Promise<any>
  onDeleteSuccessful: () => any
}

export default function FileDeleteButton({
  onDelete,
  onDeleteSuccessful,
  fileName,
}: FileDeleteButtonProps) {
  const [{ loading: deleting }, deleteData] = useAsync(
    useCallback(
      () =>
        onDelete()
          .then(() => {
            successToast('Delete successful')
            onDeleteSuccessful()
          })
          .catch((e) => {
            errorToast(getErrorString(e), e.retry)
          }),
      [onDelete, onDeleteSuccessful]
    )
  )

  return (
    <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
      <Button intent={Intent.DANGER} minimal title="Delete" icon="trash" />
      <div>
        <div className={Classes.ALERT_BODY}>
          <Icon icon={'trash'} iconSize={40} intent={Intent.DANGER} />
          <div className={Classes.ALERT_CONTENTS}>
            Are you sure you want to delete the file <b>{fileName}</b>?
          </div>
        </div>
        <div className={Classes.ALERT_FOOTER}>
          <Button
            intent={Intent.DANGER}
            text="Delete"
            loading={deleting}
            onClick={deleteData}
          />
          <Button className={Classes.POPOVER_DISMISS} text="Cancel" />
        </div>
      </div>
    </Popover>
  )
}
