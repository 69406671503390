import { stripExtension } from '../../../lib/utils'
import { parseFrontmatter } from '../../helpers'

export enum SetlistItemType {
  HEADING = 'heading',
  SONG = 'song',
}

export type Meta = { [k: string]: string }

export type SetListItem =
  | { type: SetlistItemType.HEADING; heading: string }
  | {
      type: SetlistItemType.SONG
      filepath: string
      index: number | null
      display_name: string
    }

export type Setlist = {
  meta: Meta
  items: SetListItem[]
}

export default function parse(setString: string): Setlist {
  const [meta, body] = parseFrontmatter(setString)
  let index = 0
  const setlist = body
    .split('\n')
    .filter((x) => x)
    .map(
      (x): SetListItem => {
        x = x.replace(/<!--.*-->/, '').trim()
        if (x.startsWith('# ')) {
          return {
            type: SetlistItemType.HEADING,
            heading: x.substr(2).trim(),
          }
        }
        const digitMatches = x.match(/^(\d+)\.\s/)
        if (x.startsWith('- ')) {
          x = x.substr(2)
          index++
        } else if (digitMatches) {
          x = x.substr(digitMatches[0].length)
          index = parseInt(digitMatches[1])
        } else {
          index = 0
        }
        return {
          type: SetlistItemType.SONG,
          index: index === 0 ? null : index,
          filepath: x,
          display_name: stripExtension(x).split('/').slice(-1)[0],
        }
      }
    )
  return {
    meta,
    items: setlist,
  }
}
