import { Link, useRouteMatch } from 'react-router-dom'
import React from 'react'
import { Classes } from '@blueprintjs/core'
import LatexDialogButton from './LatexDialogButton'
import { useGitLabFile } from '../../components/page/helpers'
import { parseFrontmatter } from '../helpers'
import { Setlist, SetlistItemType } from './utils/parse'
import { classNames } from 'lib/utils'

function SetlistSong({
  index,
  songPath,
}: {
  index: number | null
  songPath: string
}) {
  const { data } = useGitLabFile('songs/' + songPath + '.md')
  const [meta] = data ? parseFrontmatter(data) : []
  const { url } = useRouteMatch()
  return (
    <Link
      className={classNames(Classes.MENU_ITEM)}
      to={`${url}/${encodeURIComponent(songPath)}`}
    >
      {index && <span>{index}.</span>}
      {meta && meta.title ? (
        <div className={Classes.FILL}>{meta.title}</div>
      ) : (
        <div className={Classes.FILL}>{songPath}</div>
      )}
    </Link>
  )
}

export default function SetlistRender({ setlist }: { setlist: Setlist }) {
  const { items, meta } = setlist

  return (
    <>
      {meta.title && <h1 style={{ marginTop: 0 }}>{meta.title}</h1>}
      <div>
        <LatexDialogButton
          icon="code"
          text="Convert to LaTeX"
          setlist={items}
        />
      </div>
      <ul className={Classes.MENU}>
        {items.map((song, i) => {
          if (song.type === SetlistItemType.HEADING) {
            return (
              <h3
                key={i}
                style={{
                  margin: 0,
                  padding: '12px 7px 5px',
                  fontSize: '16px',
                  textDecoration: 'underline',
                }}
              >
                <strong>{song.heading}</strong>
              </h3>
            )
          }
          return (
            <li key={i}>
              <SetlistSong index={song.index} songPath={song.filepath} />
            </li>
          )
        })}
      </ul>
    </>
  )
}
