import yaml from 'js-yaml'

const optionalByteOrderMark = '\\ufeff?'
const platform = typeof process !== 'undefined' ? process.platform : ''
const pattern =
  '^(' +
  optionalByteOrderMark +
  '(= yaml =|---)' +
  '$([\\s\\S]*?)' +
  '^(?:\\2|\\.\\.\\.)\\s*' +
  '$' +
  (platform === 'win32' ? '\\r?' : '') +
  '(?:\\n)?)'
// NOTE: If this pattern uses the 'g' flag the `regex` variable definition will
// need to be moved down into the functions that use it.
const regex = new RegExp(pattern, 'm')

function splitFrontmatter(data: string) {
  const lines = data.split(/(\r?\n)/)
  if (!lines[0] || !/= yaml =|---/.test(lines[0])) {
    return {
      attributes: {},
      body: data,
    }
  }
  const match = regex.exec(data)
  if (!match) {
    return {
      attributes: {},
      body: data,
    }
  }

  const yamlStr = match[match.length - 1].replace(/^\s+|\s+$/g, '')
  const body = data.replace(match[0], '')

  return {
    body: body,
    frontmatter: yamlStr,
  }
}

export function parseFrontmatter(
  data: string
): [{ [key: string]: any }, string] {
  const parsed = splitFrontmatter(data)
  let attributes = {}
  try {
    attributes = parsed.frontmatter ? yaml.load(parsed.frontmatter) : {}
    if (typeof attributes === 'string') {
      attributes = {}
    }
  } catch (e) {
    console.warn(e)
    attributes = {}
  }

  return [attributes, parsed.body]
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
