import React from 'react'
import { Classes, Intent, Spinner } from '@blueprintjs/core'
import { classNames } from 'lib/utils'

const AppSpinner: React.FC<{}> = ({ children }) => {
  return (
    <div
      style={{
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
      className={classNames(Classes.HEADING, Classes.TEXT_MUTED)}
    >
      <Spinner intent={Intent.PRIMARY} />
      {children && <br />}
      {children}
    </div>
  )
}

export default AppSpinner
