import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styles from './SongPresentation.module.css'
import { Icon } from '@blueprintjs/core'
import { classNames } from 'lib/utils'
import { Song, SongLine, SongLyrics } from './types'

function Portal({ children }: React.PropsWithChildren<{}>) {
  const el = useRef<HTMLDivElement | null>(null)
  const getEl = () => {
    if (el.current !== null) {
      return el.current
    }
    el.current = document.createElement('div')
    el.current.style.position = 'fixed'
    el.current.style.left = '0'
    el.current.style.right = '0'
    el.current.style.bottom = '0'
    el.current.style.top = '0'
    el.current.style.zIndex = '10'
    return el.current
  }
  useEffect(() => {
    document.body.classList.add(styles.bodyModalOpen)
    document.body.appendChild(getEl())
    return () => {
      document.body.classList.remove(styles.bodyModalOpen)
      document.body.removeChild(getEl())
    }
  }, [el])
  return createPortal(children, getEl())
}

/* View in fullscreen */
function goFullscreen() {
  const elem = document.documentElement as any
  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen()
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen()
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen()
  }
}

type SongPresentationProps = {
  onClose: () => any
  song: Song
}

export default function SongPresentation({
  onClose,
  song,
}: SongPresentationProps) {
  const slideRefs = useRef<any>({})
  const [currentIndex, setIndex] = useState(0)
  const [fontSize, setFontSize] = useState(100)
  // Keyboard shortcuts
  useEffect(() => {
    const fn = (event: KeyboardEvent) => {
      if (event.keyCode === 27 || event.keyCode === 81) {
        // Esc or q key
        onClose()
      }
      if (event.keyCode === 70) {
        // f key
        goFullscreen()
      }
      if (event.keyCode === 37) {
        // Left
        setIndex((i) => Math.max(0, i - 1))
      }
      if (event.keyCode === 39) {
        // Right
        setIndex((i) => Math.min(i + 1, song.lyrics.length))
      }
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        // numbers on top of keyboard
        setIndex(Math.min(event.keyCode - 48, song.lyrics.length))
      }
      if (event.keyCode >= 96 && event.keyCode <= 105) {
        // numbers on numeric keypad
        setIndex(Math.min(event.keyCode - 96, song.lyrics.length))
      }
    }
    document.addEventListener('keydown', fn, false)
    return () => {
      document.removeEventListener('keydown', fn, false)
    }
  }, [onClose, setIndex, song])

  // Scroll to stanza
  useEffect(() => {
    const goToStanza = (i: number) => {
      const element = slideRefs.current[i]
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
    goToStanza(currentIndex)
  }, [currentIndex])

  const renderLine = (line: SongLine, i: number) => (
    <div key={i}>{line.parts.map((item) => item.text).join('')}</div>
  )

  const renderLyrics = (lyrics: SongLyrics) => {
    return lyrics.map((stanza, i) => (
      <div
        key={i}
        ref={(el) => (slideRefs.current[i + 1] = el)}
        className={classNames(
          styles.stanza,
          styles[stanza.type],
          currentIndex === i + 1 && styles.active
        )}
        onClick={() => setIndex(i + 1)}
      >
        {stanza.paragraphs &&
          stanza.paragraphs.map((paragraph) => paragraph.lines.map(renderLine))}
      </div>
    ))
  }

  return (
    <Portal>
      <div className={styles.container} style={{ fontSize: `${fontSize}%` }}>
        <Icon className={styles.closeIcon} icon="cross" onClick={onClose} />
        <div className={styles.bottomRight}>
          <Icon
            className={styles.fzPlusIcon}
            icon="plus"
            onClick={() => setFontSize((fz) => Math.min(200, fz + 10))}
          />
          <div className={styles.fzReset} onClick={() => setFontSize(100)}>
            {fontSize}%
          </div>
          <Icon
            className={styles.fzMinusIcon}
            icon="minus"
            onClick={() => setFontSize((fz) => Math.max(0, fz - 10))}
          />
          <Icon
            className={styles.fullScreenIcon}
            icon="fullscreen"
            onClick={goFullscreen}
          />
          {currentIndex !== 0 && (
            <div className={styles.slideNumber}>
              {currentIndex}/{song.lyrics.length}
            </div>
          )}
        </div>
        {song.meta.title && (
          <h1
            className={classNames(
              styles.title,
              currentIndex === 0 && styles.active
            )}
            onClick={() => setIndex(0)}
            ref={(el) => (slideRefs.current[0] = el)}
          >
            {song.meta.title}
          </h1>
        )}
        <div className={styles.lyrics}>
          {song.lyrics && renderLyrics(song.lyrics)}
        </div>
      </div>
    </Portal>
  )
}
