import React from 'react'
import ErrorPage from '../components/ErrorPage'
import { Classes } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <ErrorPage
      error="Page not found"
      action={
        <Link to="/" className={Classes.BUTTON}>
          Go to Homepage
        </Link>
      }
    />
  )
}
