import { Button, Classes, Intent } from '@blueprintjs/core'
import React from 'react'
import { useLogin } from 'gitlab/auth'

export default function LoginPage() {
  const { loginRedirect, redirecting } = useLogin()

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        large
        type="submit"
        intent={Intent.SUCCESS}
        loading={redirecting}
        onClick={loginRedirect}
        icon="log-in"
      >
        Login with GitLab
      </Button>
      <div
        className={Classes.TEXT_MUTED}
        style={{ visibility: !redirecting && 'hidden', padding: '.5em' }}
      >
        Redirecting to GitLab login...
      </div>
    </div>
  )
}
