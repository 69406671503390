const CharType = {
  AYTHAM: 1,
  INDEPENDENT_VOWEL: 2,
  CONSONANT: 3,
  DEPENDENT_VOWEL: 4,
  VIRAMA: 5,
  DIGIT: 6,
  NUMERIC: 7,
  OTHERS: 8,
  DEFAULT: 9,
  CANDRABINDU: 10,
  ANUSVARA: 11,
  VISARGA: 12,
  FRACTION: 13,
  CHILLU: 14,
  ZERO_WIDTH_JOINER: 15,
  ZERO_WIDTH_NON_JOINER: 16,
  SIGN: 17,
  UDATTA: 18,
  STRESS: 19,
  ACCENT: 20,
  SPACE: 21,
}

const charmap: { [k: string]: [string, number] } = {
  ஃப: ['f', CharType.OTHERS],
  ஃஸ்: ['x', CharType.OTHERS],
  ஃஜ: ['z', CharType.OTHERS],
  ஃ: ['k', CharType.AYTHAM],
  அ: ['a', CharType.INDEPENDENT_VOWEL],
  ஆ: ['aa', CharType.INDEPENDENT_VOWEL], // ā
  இ: ['i', CharType.INDEPENDENT_VOWEL],
  ஈ: ['ee', CharType.INDEPENDENT_VOWEL], // ī
  உ: ['u', CharType.INDEPENDENT_VOWEL],
  ஊ: ['oo', CharType.INDEPENDENT_VOWEL], // ū
  எ: ['e', CharType.INDEPENDENT_VOWEL],
  ஏ: ['e', CharType.INDEPENDENT_VOWEL], // ē
  ஐ: ['ai', CharType.INDEPENDENT_VOWEL],
  ஒ: ['o', CharType.INDEPENDENT_VOWEL],
  ஓ: ['oa', CharType.INDEPENDENT_VOWEL], // ō
  ஔ: ['au', CharType.INDEPENDENT_VOWEL],
  க: ['ka', CharType.CONSONANT],
  ங: ['nga', CharType.CONSONANT],
  ச: ['cha', CharType.CONSONANT], // sa
  ஜ: ['ja', CharType.CONSONANT],
  ஞ: ['ny', CharType.CONSONANT],
  ட: ['da', CharType.CONSONANT],
  ண: ['na', CharType.CONSONANT],
  த: ['tha', CharType.CONSONANT],
  ந: ['na', CharType.CONSONANT],
  ன: ['na', CharType.CONSONANT],
  ப: ['pa', CharType.CONSONANT],
  ம: ['ma', CharType.CONSONANT],
  ய: ['ya', CharType.CONSONANT],
  ர: ['ra', CharType.CONSONANT],
  ற: ['ra', CharType.CONSONANT],
  ல: ['la', CharType.CONSONANT],
  ள: ['la', CharType.CONSONANT],
  ழ: ['la', CharType.CONSONANT], // zha
  வ: ['va', CharType.CONSONANT],
  ஶ: ['sha', CharType.CONSONANT],
  ஷ: ['sha', CharType.CONSONANT],
  ஸ: ['sa', CharType.CONSONANT],
  ஹ: ['ha', CharType.CONSONANT],
  'ா': ['aa', CharType.DEPENDENT_VOWEL],
  'ி': ['i', CharType.DEPENDENT_VOWEL],
  'ீ': ['ee', CharType.DEPENDENT_VOWEL],
  'ு': ['u', CharType.DEPENDENT_VOWEL],
  'ூ': ['oo', CharType.DEPENDENT_VOWEL],
  'ெ': ['e', CharType.DEPENDENT_VOWEL],
  'ே': ['e', CharType.DEPENDENT_VOWEL],
  'ை': ['ai', CharType.DEPENDENT_VOWEL],
  'ொ': ['o', CharType.DEPENDENT_VOWEL],
  'ோ': ['o', CharType.DEPENDENT_VOWEL],
  'ௌ': ['au', CharType.DEPENDENT_VOWEL],
  '்': ['pulli', CharType.VIRAMA],
  '௦': ['0', CharType.DIGIT],
  '௧': ['1', CharType.DIGIT],
  '௨': ['2', CharType.DIGIT],
  '௩': ['3', CharType.DIGIT],
  '௪': ['4', CharType.DIGIT],
  '௫': ['5', CharType.DIGIT],
  '௬': ['6', CharType.DIGIT],
  '௭': ['7', CharType.DIGIT],
  '௮': ['8', CharType.DIGIT],
  '௯': ['9', CharType.DIGIT],
  '௰': ['10', CharType.NUMERIC],
  '௱': ['100', CharType.NUMERIC],
  '௲': ['1000', CharType.NUMERIC],
  '௺': ['No. ', CharType.OTHERS],
  '௳': ['day ', CharType.OTHERS],
  '௴': ['month ', CharType.OTHERS],
  '௵': ['year ', CharType.OTHERS],
  '௶': ['debit ', CharType.OTHERS],
  '௷': ['credit ', CharType.OTHERS],
  '௸': ['as above ', CharType.OTHERS],
  '௹': ['Rupee ', CharType.OTHERS],
}

export default function transliterate(input: string) {
  let english = ''

  for (let i = 0; i < input.length; ++i) {
    const c = input[i]
    const [engCh = c, type = CharType.DEFAULT] = charmap[c] || []

    switch (type) {
      case CharType.AYTHAM:
      case CharType.INDEPENDENT_VOWEL:
      case CharType.CONSONANT:
        english += engCh
        break
      case CharType.DEPENDENT_VOWEL:
        english = english.substring(0, english.length - 1)
        english += engCh
        break
      case CharType.VIRAMA:
        english = english.substring(0, english.length - 1)
        break
      case CharType.DIGIT:
      case CharType.NUMERIC:
      case CharType.OTHERS:
      default:
        english += c
        break
    }
  }

  english = english.replace(/thth/g, 'th')
  english = english.replace(/chch/g, 'ch')
  english = english.replace(/enr/g, 'ent')
  english = english.replace(/iyechu/g, 'yesu')
  english = english.replace(/rr/g, 'tr')
  english = english.replace(/nr/g, 'nt')
  english = english.replace(/ruch/g, 'rus') // ruchitha
  english = english.replace(/nech/g, 'nes') // nechamaay
  english = english.replace(/eech/g, 'ees') // veechuvom
  return english
}
