import { useGitLabApi } from '../../gitlab/gitlabApi'
import { useAsync } from '../../lib/effects'
import { useCallback, useMemo } from 'react'
import { Base64 } from 'js-base64'

export function useGitLabTree(path: string, treeView: boolean) {
  const { getRepositoryTree } = useGitLabApi()

  const [{ data: tree, error, loading }, reFetchData] = useAsync(
    useCallback(() => getRepositoryTree(path, { recursive: !treeView }), [
      path,
      treeView,
      getRepositoryTree,
    ]),
    true
  )
  return { tree, error, loading, reFetchData }
}

export function useGitLabFile(path: string, useCacheOnly = false) {
  const { getFile } = useGitLabApi()
  const [{ data, error, loading }, reFetchData] = useAsync(
    useCallback(() => getFile(path, useCacheOnly), [
      path,
      getFile,
      useCacheOnly,
    ]),
    !!path
  )
  const decodedFileContent = useMemo(
    () => data && Base64.decode(data.content),
    [data]
  )
  return { file: data, data: decodedFileContent, error, loading, reFetchData }
}
