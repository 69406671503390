import Chord from './chord'

const SHARP = '♯'
const FLAT = '♭'

export default function formatChord(
  chordString: string,
  transpose: number,
  modifier: string
): string {
  if (!chordString) return ''
  if (/\s/g.test(chordString))
    return chordString.replace(/\S+/g, (match) =>
      formatChord(match, transpose, modifier)
    )
  let chord = Chord.parse(chordString)
  if (chord === null) return chordString
  chord = chord.transpose(transpose)
  chord = chord.normalize()
  if (modifier) {
    chord = chord.useModifier(modifier)
  }
  return chord.toString()
}

export function getChordModifier(
  keyChordString: string,
  transpose: number
): '#' | 'b' {
  let chord = Chord.parse(keyChordString)
  if (chord) {
    chord = chord.transpose(transpose)
    chord = chord.useModifier('#').normalize()
    const { base, modifier } = chord
    if (chord.suffix === 'm') {
      // minor
      if (
        (modifier === '#' && /^[FCG]$/.test(base)) ||
        (modifier === null && /^[AEB]$/.test(base))
      ) {
        return '#'
      }
      if (
        (modifier === null && /^[DGCF]$/.test(base)) ||
        (modifier === '#' && /^[AD]$/.test(base))
      ) {
        return 'b'
      }
    } else {
      // major
      if (
        (modifier === '#' && /^F$/.test(base)) ||
        (modifier === null && /^[CGDAEB]$/.test(base))
      ) {
        return '#'
      }
      if (
        (modifier === null && /^F$/.test(base)) ||
        (modifier === '#' && /^[ADGCF]$/.test(base))
      ) {
        return 'b'
      }
    }
  }
  return '#'
}

export function beautifyChord(chord: string) {
  return chord.replace('#', SHARP).replace('b', FLAT)
}
