import { GitFile } from 'core/GitFile'
import { ReactElement } from 'react'
import SetlistRender from './SetlistRender'
import parseSetlist, { Setlist } from './utils/parse'

class SetlistFile implements GitFile<Setlist> {
  basePath = 'setlists'
  title = 'Set lists'

  template = `
---
title: Enter title here
---

# Title

- path/to/song
  `.trim()

  parse(str: string): Setlist {
    return parseSetlist(str)
  }

  getTitle(set: Setlist): string {
    return set.meta.title || ''
  }

  renderFile(set: Setlist): ReactElement<any, any> {
    return <SetlistRender setlist={set} />
  }

  renderForm(
    str: Setlist,
    onChange: (v: string) => any
  ): ReactElement<any, any> {
    return <SetlistRender setlist={str} />
  }
}

export default new SetlistFile()
