import { GitFile } from 'core/GitFile'
import { ReactElement } from 'react'
import SongRender from './SongRender'
import parseSong from './utils/parse'
import { Song } from './types'

class SongFile implements GitFile<Song> {
  basePath = 'songs'
  title = 'Songs'

  template = `
---
title: Enter title here
subtitle: Enter subtitle here
url: https://www.url.com/if/any
key: C
tempo: 0
time: 4/4
---
  `.trim()

  parse(str: string): Song {
    return parseSong(str)
  }

  getTitle(song: Song): string {
    return song.meta.title || ''
  }

  renderFile(song: Song): ReactElement<any, any> {
    return <SongRender song={song} />
  }

  renderForm(song: Song, onChange: (v: string) => any): ReactElement<any, any> {
    return <SongRender song={song} />
  }
}

export default new SongFile()
