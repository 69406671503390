import { jsonFetch } from './fetch'

const BASE_URL = 'https://gitlab.com/api/v4'
const REF = 'master'

function encodeQueryData(data = {}) {
  const ret = []
  let qn = ''
  for (let d in data) {
    if (data[d]) {
      qn = '?'
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    }
  }
  return qn + ret.join('&')
}

const gitlab = (accessToken) => ({
  get: async (path, queryParams) => {
    return await jsonFetch(
      `${BASE_URL}${path}${encodeQueryData(queryParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
      }
    )
  },
  post: async (path, data) => {
    return await jsonFetch(`${BASE_URL}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
      body: JSON.stringify(data),
    })
  },
})

export const getRepositoryTree = (projectId, accessToken) => (
  path,
  { ref = REF, recursive = true, page = 1, per_page = 100 } = {}
) =>
  gitlab(accessToken).get(`/projects/${projectId}/repository/tree`, {
    path,
    ref,
    recursive,
    page,
    per_page,
  })

export const getFile = (projectId, accessToken) => (filePath, ref = REF) =>
  gitlab(accessToken).get(
    `/projects/${projectId}/repository/files/${encodeURIComponent(filePath)}`,
    {
      ref,
    }
  )

export const getLatestCommit = (projectId, accessToken, ref) =>
  gitlab(accessToken).get(`/projects/${projectId}/repository/commits/${ref}`)

export const createCommit = (projectId, accessToken) => (
  commit_message,
  actions,
  { ref = REF } = {}
) =>
  gitlab(accessToken).post(`/projects/${projectId}/repository/commits`, {
    branch: ref,
    commit_message: commit_message,
    actions,
  })

export const getUser = (accessToken) => () => gitlab(accessToken).get(`/user`)

export const getProject = (projectId, accessToken) => () =>
  gitlab(accessToken).get(`/projects/${projectId}`)

export const getProjectMember = (projectId, accessToken) => (userId) =>
  gitlab(accessToken).get(`/projects/${projectId}/members/${userId}`)

export const getGroupMember = (projectId, accessToken) => (userId) =>
  gitlab(accessToken).get(
    `/groups/${decodeURIComponent(projectId).split('/')[0]}/members/${userId}`
  )

export const searchFiles = (projectId, accessToken, ref) => (search) =>
  search === ''
    ? []
    : gitlab(accessToken).get(`/projects/${projectId}/search`, {
        scope: 'blobs',
        search: search,
        ref,
      })

export const getGitLabLink = (projectId, path, { ref = REF } = {}) => {
  return `https://gitlab.com/${decodeURIComponent(
    projectId
  )}/blob/${ref}/${path}`
}
