import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Layout from './components/Layout'
import LoginPage from './pages/LoginPage'
import NotFound from './pages/NotFound'
import { useAuth } from './gitlab/auth'
import AppSpinner from './components/AppSpinner'
import SearchPage from 'pages/SearchPage'
import { SongListPage, SongDetailsPage, SongCreatePage } from 'pages/Song'
import { SetListPage, SetDetailsPage, SetCreatePage } from 'pages/Setlist'
import Text2Song from 'pages/Tools/Text2Song'
import BatchAddSongs from 'pages/Tools/BatchAddSongs'

function Routes() {
  const { isLoggedIn, projectId } = useAuth()

  if (!projectId && !isLoggedIn) {
    return <Route exact component={LoginPage} />
  }

  return (
    <Layout>
      <Suspense fallback={<AppSpinner />}>
        <Switch>
          <Route exact path="/" component={() => <Redirect to="/songs" />} />
          <Route
            exact
            path="/oauth/redirect"
            component={() => <Redirect to="/" />}
          />
          <Route exact path="/songs" component={SongListPage} />
          <Route path="/songs/create" component={SongCreatePage} />
          <Route path="/songs/:id" component={SongDetailsPage} />

          <Route exact path="/setlists" component={SetListPage} />
          <Route path="/setlists/create" component={SetCreatePage} />
          <Route path="/setlists/:id" component={SetDetailsPage} />

          <Route exact path="/search" component={SearchPage} />
          <Route exact path="/tools/text2song" component={Text2Song} />
          <Route
            exact
            path="/tools/batch-add-songs"
            component={BatchAddSongs}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Layout>
  )
}

export default Routes
