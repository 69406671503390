import React, { useState } from 'react'
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Callout,
  Classes,
  Divider,
  Tag,
} from '@blueprintjs/core'

import styles from './Song.module.css'
import { transposeSong } from './utils/parse'
import transliterateString from './utils/transliterate'
import SongPresentation from './SongPresentation'
import { useSetting } from '../../lib/settings'
import { classNames } from 'lib/utils'
import {
  SongLyrics,
  SongLine,
  SongLinePart,
  Song,
  SongStanzaType,
} from './types'
import { capitalizeFirstLetter } from 'core/helpers'

const SHARP = '♯'
const FLAT = '♭'

type SongProps = {
  song: Song
}

export default function SongRender({ song }: SongProps) {
  const [transpose, setTranspose] = useState(0)
  const [modifier, setModifier] = useState<'#' | 'b'>()
  const [fontSize, setFontSize] = useSetting('font_size', 100)
  const [transliterate, setTransliterate] = useSetting('transliterate', false)
  const [presenting, setPresenting] = useState(false)
  const [showChords, setShowChords] = useSetting('show_chords', true)

  console.log(song)
  const parsedSong = transposeSong(
    song,
    transpose,
    modifier,
    transliterate ? transliterateString : (x) => x
  )
  // console.log(parsedSong)

  return (
    <>
      <ButtonGroup className={styles.toolbar}>
        <Button minimal={true} text="Transpose" />
        <ButtonGroup>
          <Button
            icon="minus"
            onClick={() => setTranspose((transpose - 1) % 12)}
          />
          <Button
            text={`${transpose > 0 ? '+' : ' '}${transpose}`}
            onClick={() => setTranspose(0)}
          />
          <Button
            icon="plus"
            onClick={() => setTranspose((transpose + 1) % 12)}
          />
        </ButtonGroup>
        <Divider />
        <ButtonGroup>
          <Button
            text={SHARP}
            onClick={() => setModifier((m) => (m === '#' ? undefined : '#'))}
            active={modifier === '#'}
          />
          <Button
            text={FLAT}
            onClick={() => setModifier((m) => (m === 'b' ? undefined : 'b'))}
            active={modifier === 'b'}
          />
        </ButtonGroup>
        <Divider />
        <Button minimal={true} text="Font" />
        <ButtonGroup>
          <Button
            icon="minus"
            onClick={() => setFontSize((s: number) => Math.max(0, s - 10))}
          />
          <Button text={`${fontSize}%`} onClick={() => setFontSize(100)} />
          <Button
            icon="plus"
            onClick={() => setFontSize((s: number) => Math.min(300, s + 10))}
          />
        </ButtonGroup>
        <Divider />
        <Button
          icon="translate"
          title="Transliterate"
          active={transliterate}
          onClick={() => setTransliterate((t: boolean) => !t)}
        />
        <Divider />
        <Button
          text="C"
          title="Show Chords"
          active={showChords}
          onClick={() => setShowChords((c: boolean) => !c)}
        />
        <Divider />
        <Button
          icon="presentation"
          title="Present"
          active={presenting}
          onClick={() => setPresenting(true)}
        />
      </ButtonGroup>
      <div
        className={classNames(styles.song, !showChords && styles.hideChords)}
        style={{ fontSize: `${fontSize}%` }}
      >
        {renderSong(parsedSong, setTranspose)}
      </div>
      {presenting && (
        <SongPresentation onClose={() => setPresenting(false)} song={song} />
      )}
    </>
  )
}

export function renderSong(song: Song, setTranspose: (t: number) => any) {
  const title = song.meta.title
  const subtitle = song.meta.subtitle
  const transpose = song.meta.transpose
  const key = song.meta.key
  const time = song.meta.time
  const tempo = song.meta.tempo
  const links = song.meta.links

  const renderLink = (link: { url: string; title: string }, i: number) => {
    return (
      <AnchorButton
        key={i}
        minimal
        icon="music"
        href={link.url}
        target="_blank"
        title={link.title || 'Listen to the Song'}
      />
    )
  }

  const renderChord = (item: SongLinePart, i: number) => (
    <div key={i} className={styles.chord}>
      {item.chord}
    </div>
  )

  const renderLyricsTextPart = (item: SongLinePart, i: number) => (
    <div key={i} className={styles.lyrics}>
      {item.text}
    </div>
  )

  const renderLine = (line: SongLine, i: number) => (
    <div key={i} className={styles.line}>
      {line.parts.some((item) => item.chord) && (
        <div className={styles.chordLine}>{line.parts.map(renderChord)}</div>
      )}
      {line.parts.some((item) => item.text) && (
        <div className={styles.lyricsLine}>
          {line.parts.map(renderLyricsTextPart)}
        </div>
      )}
    </div>
  )

  const renderLyrics = (lyrics: SongLyrics) => {
    return (
      <div className={styles.chordSheet}>
        {lyrics.map((stanza, i) => (
          <div
            key={i}
            className={classNames(
              Classes.CARD,
              styles.part,
              styles[stanza.type]
            )}
          >
            <div
              className={classNames(
                styles.index,
                Classes.TAG,
                stanza.type === SongStanzaType.Chorus && Classes.INTENT_PRIMARY,
                [
                  SongStanzaType.Intro,
                  SongStanzaType.Outro,
                  SongStanzaType.Interlude,
                ].includes(stanza.type) && Classes.MINIMAL
              )}
            >
              {capitalizeFirstLetter(stanza.type) +
                (stanza.number ? ` ${stanza.number}` : '')}
            </div>
            <div className={styles.paragraphs}>
              {stanza.paragraphs &&
                stanza.paragraphs.map((paragraph, i) => (
                  <div key={i} className={styles.paragraph}>
                    {paragraph.lines.map(renderLine)}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      {title && (
        <h1 className={classNames(Classes.HEADING, styles.title)}>{title}</h1>
      )}
      {subtitle && (
        <h2 className={classNames(Classes.HEADING, styles.subtitle)}>
          {subtitle}
        </h2>
      )}
      {(key || transpose || time || tempo) && (
        <Callout className={styles.songToolbar}>
          {[
            key && (
              <div>
                Key <Tag>{key}</Tag>
              </div>
            ),
            transpose && (
              <div>
                Transpose{' '}
                <Tag onClick={() => setTranspose(parseInt(transpose))}>
                  {transpose}
                </Tag>
              </div>
            ),
            time && (
              <div>
                Time <Tag>{time}</Tag>
              </div>
            ),
            tempo && (
              <div>
                Tempo <Tag>{tempo}</Tag>
              </div>
            ),
            links && links.map(renderLink),
          ]
            .filter((x) => x)
            .reduce(
              (acc, x, i) =>
                i === 0 ? (
                  x
                ) : (
                  <>
                    {acc}
                    <Divider />
                    {x}
                  </>
                ),
              <></>
            )}
        </Callout>
      )}
      {song.lyrics && renderLyrics(song.lyrics)}
    </>
  )
}
