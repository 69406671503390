import React from 'react'
import { useParams } from 'react-router-dom'
import SongFile from 'core/song/SongFile'
import FileListPage from 'components/page/FileListPage'
import FileCreatePage from 'components/page/FileCreatePage'
import FileDetailsPage from '../components/page/FileDetailsPage'

export function SongDetailsPage() {
  const { id } = useParams<{ id: string }>()
  return <FileDetailsPage path={id} gitFile={SongFile} />
}

export function SongListPage() {
  return <FileListPage gitFile={SongFile} />
}

export function SongCreatePage() {
  return <FileCreatePage gitFile={SongFile} />
}
