const public_url =
  process.env.PUBLIC_URL ||
  `${window.location.protocol}//${window.location.host}`

const config = {
  gitlab_client_id: encodeURIComponent(
    process.env.REACT_APP_GITLAB_CLIENT_ID || ''
  ),
  gitlab_project_id: encodeURIComponent(
    process.env.REACT_APP_GITLAB_REPO || ''
  ),
  // gitlab_project_id: encodeURIComponent('blesswinsamuel/songs'),
  // gitlab_project_id: encodeURIComponent('blesswinsamuel/songs-demo'),
  gitlab_branch: encodeURIComponent(
    process.env.REACT_APP_GITLAB_BRANCH || 'master'
  ),
  gitlab_redirect_uri: public_url,
  public_url: public_url,
}
export default config
