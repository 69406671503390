import { parseFrontmatter } from '../../helpers'
import formatChord, { beautifyChord, getChordModifier } from './formatChord'
import {
  SongLinePart,
  SongLyrics,
  Song,
  SongStanzaType,
  SongMeta,
} from '../types'

function parseLyricsLine(line: string): SongLinePart[] {
  const regex = /(?:\[([^\]]*)])?([^[]*)/g
  const matches = line.match(regex)
  const parsedLine: SongLinePart[] = []
  if (!matches) return parsedLine
  for (let match of matches) {
    const m = match.match(/^(?:\[([^\]]*)])?(.*)$/)
    if (m) {
      const [, chord, lyrics] = m
      if (chord || lyrics) {
        parsedLine.push({
          chord: chord,
          text: lyrics,
        })
      }
    }
  }
  return parsedLine
}

function parseLyrics(lyricsString: string): SongLyrics {
  const lyrics: SongLyrics = []

  let i = -1
  let verseNumber = 0
  let isInBlock = false

  for (let line of lyricsString.split('\n')) {
    line = line.trim()
    if (!isInBlock) {
      const directiveMatch = line.match(/^```(.*)?$/i)
      if (directiveMatch) {
        // start of new block
        isInBlock = !isInBlock
        const [, tag] = directiveMatch
        const [name, value] = tag ? tag.split(' ') : []
        switch (name) {
          case SongStanzaType.Chorus:
          case SongStanzaType.Bridge:
          case SongStanzaType.Tag:
          case SongStanzaType.Intro:
          case SongStanzaType.Outro:
          case SongStanzaType.Interlude:
            lyrics[++i] = {
              paragraphs: [],
              type: name,
              number: Number(value),
            }
            break
          case SongStanzaType.Verse:
          default:
            let v = Number(value)
            if (value === '*') {
              v = 0
            } else if (!value) {
              v = ++verseNumber
            } else if (value) {
              verseNumber = v
            }
            lyrics[++i] = {
              paragraphs: [],
              type: SongStanzaType.Verse,
              number: v,
            }
            break
        }
      } else {
        // normal line outside code block
        if (i === -1) {
          // create new paragraph
          lyrics[++i] = {
            paragraphs: [],
            type: SongStanzaType.Verse,
            number: 0,
          }
        }
        const currentStanza = lyrics[i]
        // add lines to paragraph
        if (line.length === 0 || currentStanza.paragraphs.length === 0) {
          currentStanza.paragraphs.push({
            lines: [],
          })
        }
        if (line.length > 0) {
          const currentParagraph =
            currentStanza.paragraphs[currentStanza.paragraphs.length - 1]

          const parsedLine = parseLyricsLine(line)
          currentParagraph.lines.push({ parts: parsedLine })
        }
      }
    } else if (isInBlock) {
      // add lines inside codeblock
      const currentStanza = lyrics[i]
      const directiveMatch = line.match(/^```$/i)
      if (directiveMatch) {
        // end of block
        isInBlock = !isInBlock
      } else {
        // add lines to stanza
        if (line.length === 0 || currentStanza.paragraphs.length === 0) {
          currentStanza.paragraphs.push({
            lines: [],
          })
        }
        if (line.length > 0) {
          const currentParagraph =
            currentStanza.paragraphs[currentStanza.paragraphs.length - 1]

          const parsedLine = parseLyricsLine(line)
          currentParagraph.lines.push({ parts: parsedLine })
        }
      }
    }
  }

  return lyrics
    .map((item) => ({
      ...item,
      paragraphs: item.paragraphs
        .map((paragraph) => ({
          lines: paragraph.lines.filter((line) => line.parts.length > 0),
        }))
        .filter((paragraph) => paragraph.lines.length > 0),
    }))
    .filter((item) => item.paragraphs.length > 0)
}

export default function parseSong(songString: string): Song {
  const [meta, content] = parseFrontmatter(songString)
  return {
    meta: meta as SongMeta,
    lyrics: parseLyrics(content),
  }
}

export function transposeSong(
  song: Song,
  transposeAmount: number,
  chordModifier: '#' | 'b' | undefined,
  mapLyrics: (v: string) => string
): Song {
  const newSong = JSON.parse(JSON.stringify(song)) as Song

  chordModifier = song.meta.key
    ? chordModifier || getChordModifier(song.meta.key, transposeAmount)
    : '#'
  for (const stanza of newSong.lyrics) {
    for (const para of stanza.paragraphs) {
      for (const line of para.lines) {
        for (const part of line.parts) {
          part.chord = part.chord
            ? beautifyChord(
                formatChord(part.chord, transposeAmount, chordModifier)
              )
            : undefined
          part.text = mapLyrics(part.text)
        }
      }
    }
  }
  newSong.meta.key = song.meta.key
    ? beautifyChord(formatChord(song.meta.key, transposeAmount, chordModifier))
    : undefined
  return newSong
}
