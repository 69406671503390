import { Link } from 'react-router-dom'
import { Button, IButtonProps, Intent } from '@blueprintjs/core'

type FileCreateButtonProps = {
  basePath: string
  path: string
} & IButtonProps

export default function FileCreateButton({
  basePath,
  path,
  ...buttonProps
}: FileCreateButtonProps) {
  const createUrl = `${basePath}/create` + (path ? `?path=${path}` : '')
  return (
    <Link to={createUrl}>
      <Button
        intent={Intent.PRIMARY}
        icon="plus"
        title="Create"
        {...buttonProps}
      />
    </Link>
  )
}
