import { Song } from '../types'

export default function stringify(song: Song): string {
  let res = '---\n'
  res += Object.entries(song.meta)
    .map(([key, value]) => `${key}: ${value}`)
    .join(`\n`)
  res += '\n---\n\n'
  for (const stanza of song.lyrics) {
    res += `\`\`\`${stanza.type}${
      stanza.number ? ` ${stanza.number === -1 ? '*' : stanza.number}` : ''
    }\n`
    const paragraphs: string[] = []
    for (const paragraph of stanza.paragraphs) {
      let pstr = ''
      for (const line of paragraph.lines) {
        for (const part of line.parts) {
          pstr += part.chord ? `[${part.chord}]${part.text}` : part.text
        }
        pstr += '\n'
      }
      paragraphs.push(pstr)
    }
    res += paragraphs.join('\n')
    res += `\`\`\`\n\n`
  }
  return res
}
