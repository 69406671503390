export function stripPrefix(prefix, str) {
  if (str.indexOf(prefix) === 0) {
    return str.slice(prefix.length)
  }
  return str
}

export function stripSuffix(suffix, str) {
  if (str.lastIndexOf(suffix) + suffix.length === str.length) {
    return str.substring(0, str.lastIndexOf(suffix))
  }
  return str
}

export function stripExtension(x) {
  return x.replace(/\.[^/.]+$/, '')
}

export function classNames(...args) {
  return args
    .flatMap((x) => {
      if (x && typeof x === 'object') {
        return Object.entries(x).map(([key, value]) => value && key)
      } else {
        return x
      }
    })
    .filter((x) => x)
    .join(' ')
}
