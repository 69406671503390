import React, { useCallback, useContext, useMemo } from 'react'
import { useAsync, useLocalStorageState } from '../lib/effects'
import * as api from './gitlabRawApi'
import * as idb from 'idb-keyval'
import { useAuth } from './auth'
import AppSpinner from 'components/AppSpinner'
import ErrorPage from 'components/ErrorPage'
import config from 'config'

// ref (branchName/commitId) -> commitJson
// const refsCache = new Store('Songs-refs', 'refs')
// commitId:path:recursive:page:perPage -> fileList
const treesCache = new idb.Store('Songs-trees', 'trees')
// commitId:path -> blobId
const filesCache = new idb.Store('Songs-files', 'files')
// blobId -> fileJson
const blobsCache = new idb.Store('Songs-blobs', 'blobs')

export const ApiCacheContext = React.createContext({
  latestCommit: null,
  ref: null,
})

const queryApis = (projectId, accessToken, commitId) => {
  const getRepositoryTree = async (path, opts = {}) => {
    const { recursive = true, page = 1, per_page = 100 } = opts
    const treeKey = [commitId, path, recursive, page, per_page].join(':')
    const cachedTree = await idb.get(treeKey, treesCache)
    if (cachedTree) {
      return cachedTree
    }
    // cache miss
    const data = await api.getRepositoryTree(projectId, accessToken)(path, {
      ref: commitId,
      ...opts,
    })
    // store in cache
    await idb.set(treeKey, data, treesCache)
    await Promise.all(
      data
        .filter((item) => item.type === 'blob')
        .map((blob) => idb.set(commitId + ':' + blob.path, blob.id, filesCache))
    )
    return data
  }

  const getCachedFile = async (filePath) => {
    const fileKey = commitId + ':' + filePath
    const cachedFileBlobId = await idb.get(fileKey, filesCache)
    if (cachedFileBlobId) {
      const cachedBlob = await idb.get(cachedFileBlobId, blobsCache)
      if (cachedBlob) return cachedBlob
    }
    return null
  }

  const getFile = async (filePath, useCacheOnly = false) => {
    const cachedFile = await getCachedFile(filePath)
    if (useCacheOnly || cachedFile) {
      return cachedFile
    }
    const data = await api.getFile(projectId, accessToken)(filePath, commitId)
    await idb.set(commitId + ':' + filePath, data.blob_id, filesCache)
    await idb.set(data.blob_id, data, blobsCache)
    return data
  }

  const searchFiles = (...args) =>
    api.searchFiles(projectId, accessToken, commitId)(...args)

  return { getRepositoryTree, getFile, getCachedFile, searchFiles }
}

const mutateApis = (projectId, accessToken, ref, setLatestCommit) => {
  const createCommit = async (commit_message, actions, opts) => {
    const json = await api.createCommit(projectId, accessToken)(
      commit_message,
      actions,
      {
        ref,
        ...opts,
      }
    )
    json.saved_at = new Date().toISOString()
    // await idb.set(ref, json, refsCache)
    setLatestCommit(json)
    return json
  }

  const performActions = async (actions) => {
    const message = actions
      .map((action) => {
        switch (action.action) {
          case 'create':
            return `Create file ${action.file_path}`
          case 'update':
            return `Update file ${action.file_path}`
          case 'move':
            return `Move file ${action.previous_path} to ${action.file_path}`
          case 'delete':
            return `Delete file ${action.file_path}`
          default:
            return null
        }
      })
      .filter((x) => x)
      .join(', ')
    if (actions.length === 0) {
      throw new Error('No changes made.')
    }
    return await createCommit(message, actions)
  }

  return { createCommit, performActions }
}

export const useGitLabApi = () => {
  const {
    latestCommit,
    setLatestCommit,
    fetchLatestCommit,
    ref,
    setRef,
  } = useContext(ApiCacheContext)
  const { projectId, token } = useAuth()
  const latestCommitId = latestCommit && latestCommit.id
  const memoQueryApis = useMemo(
    () => queryApis(projectId, token, latestCommitId),
    [projectId, token, latestCommitId]
  )
  const memoMutateApis = useMemo(
    () => mutateApis(projectId, token, ref, setLatestCommit),
    [projectId, token, ref, setLatestCommit]
  )
  return {
    latestCommit,
    fetchLatestCommit,
    ref,
    setRef,
    ...memoQueryApis,
    ...memoMutateApis,
  }
}

export function GitLabApiProvider(props) {
  const { projectId, token } = useAuth()

  const [latestCommit, setLatestCommit] = useLocalStorageState('latestCommit')
  const [ref, setRef] = useLocalStorageState('ref', config.gitlab_branch)

  const [{ loading, error }, fetchLatestCommit] = useAsync(
    useCallback(async () => {
      if (projectId) {
        const commit = await api.getLatestCommit(projectId, token, ref)
        setLatestCommit(commit)
      }
    }, [setLatestCommit, projectId, token, ref]),
    true
  )

  if (!latestCommit && error) {
    return <ErrorPage error="Unable to fetch latest commi" />
  }

  if (!latestCommit && loading) {
    return <AppSpinner>Fetching latest commit</AppSpinner>
  }

  return (
    <ApiCacheContext.Provider
      value={{
        latestCommit,
        setLatestCommit,
        fetchLatestCommit,
        ref,
        setRef,
      }}
    >
      {props.children}
    </ApiCacheContext.Provider>
  )
}
