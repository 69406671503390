import { useCallback, useMemo, useState } from 'react'
import { useAsync } from '../lib/effects'
import {
  Button,
  ButtonGroup,
  TextArea,
  InputGroup,
  Classes,
  Tabs,
  Tab,
} from '@blueprintjs/core'
import styles from './form.module.css'
import { getErrorString } from './ErrorPage'
import { handleStringChange } from '../lib/formHandlers'
import { errorToast, successToast } from './Toaster'
import { classNames } from 'lib/utils'
import { GitFile } from 'core/GitFile'
import transliterate from 'core/song/utils/transliterate'

function createSlug(title: string) {
  const transliterated = transliterate(title)
  return transliterated
    .toLowerCase()
    .replace(/^\s+|\s+$/g, '') // trim
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
}

type FileCreateProps<T> = {
  gitFile: GitFile<T>
  filePath: string
  onSubmit: (v: string, filepath: string) => Promise<any>
  onSubmitSuccessful?: (newPath: string) => void
  onCancelEditing: () => any
}

export default function FileCreate<T>({
  gitFile,
  filePath,
  onSubmit,
  onSubmitSuccessful = () => {},
  onCancelEditing,
}: FileCreateProps<T>) {
  const [value, setValue] = useState(gitFile.template)
  const parsed = useMemo(() => gitFile.parse(value), [gitFile, value])
  const title = gitFile.getTitle(parsed)
  const slug = createSlug(title)
  const fullPath = [filePath, slug].filter((x) => x).join('/')
  const [{ loading: submitting }, submitData] = useAsync(
    useCallback(async () => {
      try {
        await onSubmit(value, fullPath)
        successToast('Create successful')
        onSubmitSuccessful(fullPath)
      } catch (e) {
        errorToast(getErrorString(e), e.retry)
      }
    }, [onSubmitSuccessful, onSubmit, fullPath, value])
  )

  return (
    <>
      <div className={styles.pathSaveCancelContainer}>
        <InputGroup
          className={styles.filePathInput}
          placeholder="File path (autogenerated from title)"
          disabled={true}
          value={fullPath}
        />
        <ButtonGroup className={styles.saveCancelButton}>
          <Button
            text="Create"
            loading={submitting}
            className={Classes.INTENT_PRIMARY}
            onClick={submitData}
          />
          <Button text="Cancel" onClick={onCancelEditing} />
        </ButtonGroup>
      </div>
      <div className={styles.editContainer}>
        <div className={styles.editArea}>
          <Tabs
            id="EditTabs"
            defaultSelectedTabId={'raw'}
            renderActiveTabPanelOnly={true}
          >
            <Tabs.Expander />
            <Tab
              id="ui"
              title="Editor"
              panel={gitFile.renderForm(parsed, handleStringChange(setValue))}
            />
            <Tab
              id="raw"
              title="Raw"
              panel={
                <TextArea
                  className={classNames(Classes.FILL, styles.editArea)}
                  value={value}
                  rows={32}
                  onChange={handleStringChange(setValue)}
                />
              }
            />
          </Tabs>
        </div>
        <div className={styles.previewArea}>{gitFile.renderFile(parsed)}</div>
      </div>
    </>
  )
}
