import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ErrorPage from '../ErrorPage'
import Spinner from '../AppSpinner'
import { getGitLabLink } from '../../gitlab/gitlabRawApi'
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Classes,
  Intent,
} from '@blueprintjs/core'
import { useAuth } from '../../gitlab/auth'
import useTitle from '../../lib/effects/useTitle'
import FileDeleteButton from '../FileDeleteButton'
import FileEdit from '../FileEdit'
import BreadcrumbsBar from '../BreadcrumbsBar'
import { useGitLabApi } from '../../gitlab/gitlabApi'
import { useGitLabFile } from './helpers'
import { classNames } from 'lib/utils'
import { GitFile } from 'core/GitFile'

type FileDetailsPageProps<T> = React.PropsWithChildren<{
  path: string
  gitFile: GitFile<T>
}>

export default function FileDetailsPage<T>({
  path,
  gitFile,
  children,
}: FileDetailsPageProps<T>) {
  const basePath = gitFile.basePath
  const title = gitFile.title
  const history = useHistory()
  const relativePath = decodeURIComponent(path)
  const filePath = basePath + '/' + relativePath + '.md'
  const { performActions } = useGitLabApi()
  useTitle(`${relativePath} | ${title}`)
  const [isEditing, setEditing] = useState(false)
  const { projectId } = useAuth()
  const { file, data, error, loading, reFetchData } = useGitLabFile(filePath)

  const authUpdateFile = useCallback(
    async (value, newFilePath) => {
      const newPath = basePath + '/' + newFilePath + '.md'
      const actions = [
        data !== value && {
          action: 'update',
          file_path: filePath,
          content: value,
        },
        newPath !== filePath && {
          action: 'move',
          file_path: newPath,
          previous_path: filePath,
        },
      ].filter((x) => x)
      return performActions(actions)
    },
    [data, basePath, performActions, filePath]
  )
  const authDeleteFile = useCallback(
    () => performActions([{ action: 'delete', file_path: filePath }]),
    [performActions, filePath]
  )

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return (
      <ErrorPage
        error={error}
        action={<Button text="Retry" onClick={reFetchData} />}
      />
    )
  }

  const parsedFile = gitFile.parse(data)
  return (
    <>
      <BreadcrumbsBar
        title={title}
        basePath={basePath}
        path={relativePath}
        isFile={true}
      >
        {!isEditing && (
          <ButtonGroup>
            <AnchorButton
              href={getGitLabLink(projectId, filePath)}
              target="_blank"
              title="GitLab Link"
              minimal
              icon="link"
            />
            <Button
              intent={Intent.PRIMARY}
              minimal
              icon="edit"
              title="Edit"
              onClick={() => setEditing(true)}
            />
            <FileDeleteButton
              onDeleteSuccessful={() => history.goBack()}
              onDelete={authDeleteFile}
              fileName={file.file_name}
            />
          </ButtonGroup>
        )}
      </BreadcrumbsBar>
      <div className={classNames(Classes.CARD, Classes.ELEVATION_2)}>
        {isEditing && (
          <FileEdit
            gitFile={gitFile}
            value={data}
            filePath={relativePath}
            onSubmit={authUpdateFile}
            onSubmitSuccessful={(newPath) =>
              history.replace(`/${basePath}/${encodeURIComponent(newPath)}`)
            }
            onCancelEditing={() => setEditing(false)}
          />
        )}
        {!isEditing && gitFile.renderFile(parsedFile)}
      </div>
      {children}
    </>
  )
}
