import { useAsync, useDebounce } from '../lib/effects'
import React, { useCallback, useState } from 'react'
import ErrorPage from '../components/ErrorPage'
import Spinner from '../components/AppSpinner'
import { Link } from 'react-router-dom'
import {
  Classes,
  Divider,
  InputGroup,
  Menu,
  NonIdealState,
  Tab,
  Tabs,
} from '@blueprintjs/core'
import { stripPrefix, classNames } from '../lib/utils'
import { handleStringChange } from '../lib/formHandlers'
import { useGitLabApi } from '../gitlab/gitlabApi'

export default function SearchPage() {
  const [filterValue, setFilterValue] = useState('')
  const [filterCategory, setFilterCategory] = useState('songs')
  const debouncedFilterValue = useDebounce(filterValue, 1000)
  const { searchFiles } = useGitLabApi()
  const [{ data: items, error, loading }] = useAsync(
    useCallback(
      () =>
        searchFiles(
          debouncedFilterValue + ` path:${filterCategory}/* extension:md`
        ),
      [searchFiles, debouncedFilterValue, filterCategory]
    ),
    true
  )

  const files = items

  const renderList = () => {
    if (loading) {
      return <Spinner />
    }

    if (error) {
      return <ErrorPage error={error} />
    }

    if (files.length === 0) {
      return (
        <NonIdealState
          icon={'search'}
          title="No search results"
          description={
            <>
              Your search didn't match any files.
              <br />
              Try searching for something else
            </>
          }
        />
      )
    }

    return (
      <Menu>
        {files.map((file, i) => (
          <li key={i}>
            <Link
              className={classNames(Classes.MENU_ITEM)}
              to={`${filterCategory}/${encodeURIComponent(
                stripPrefix(`${filterCategory}/`, file.basename)
              )}`}
            >
              {stripPrefix(`${filterCategory}/`, file.basename)}
              <br />
              {file.data}
            </Link>
          </li>
        ))}
      </Menu>
    )
  }

  return (
    <div className={classNames(Classes.CARD, Classes.ELEVATION_2)}>
      <Tabs
        id="searchCategory"
        onChange={setFilterCategory}
        selectedTabId={filterCategory}
      >
        <InputGroup
          className={Classes.FILL}
          leftIcon="search"
          onChange={handleStringChange(setFilterValue)}
          placeholder={`Search ${filterCategory.replace('-', ' ')}...`}
          type="search"
          value={filterValue}
        />
        <Tab id="songs" title="Songs" />
        <Tab id="setlists" title="Setlists" />
      </Tabs>
      <Divider />
      {renderList()}
    </div>
  )
}
