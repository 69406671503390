import React, { useCallback, useState } from 'react'
import styles from './tools.module.css'
import { Button, Card, Intent, TextArea } from '@blueprintjs/core'
import { useAsync, useLocalStorageState } from 'lib/effects'
import Toaster from '../../components/Toaster'
import Song from '../../core/song/SongRender'
import { useGitLabApi } from '../../gitlab/gitlabApi'
import { getErrorString } from '../../components/ErrorPage'
import parseSong from 'core/song/utils/parse'

function convert(songsStr: string): [string, string][] {
  const songs = songsStr.split(/\n=+\n/g)
  return songs
    .filter((x) => !!x.trim())
    .map((song) => {
      // console.log(song)
      const songLines = song
        .trim()
        .split(/\n\n/)
        .filter((x) => !!x.trim())
      const songInfo = songLines.shift()!
      const [songFileName, songSubtitle] = (songInfo || '').split('\n')
      let songStr = songLines
        .map((stanza) => {
          stanza = stanza.replace(/^\n+/, '')
          const trimmedStanza = stanza
            .split('\n')
            .map((x) => x.trim())
            .filter((x) => x)
            .join('\n')
          const chorusRegex = /^\s+/
          if (chorusRegex.test(stanza)) {
            return `{chorus}\n${trimmedStanza}\n{/chorus}`
          }
          const numberedVerseRegex = /^(\d+)\.\s*/i
          if (numberedVerseRegex.test(trimmedStanza)) {
            const matches = numberedVerseRegex.exec(trimmedStanza)
            if (!matches) {
              return `{verse:*}\n${trimmedStanza}\n{/verse}`
            }
            return `{verse}\n${trimmedStanza.replace(
              // [verse ${matches[1]}]
              numberedVerseRegex,
              ''
            )}\n{/verse}`
          }
          return `{verse:*}\n${trimmedStanza}\n{/verse}`
        })
        .join('\n\n')
      songStr = `title: ${
        songLines.length > 0
          ? songLines[0]
              .split('\n')
              .map((x) => x.trim().replace(/^(\d+)\.\s*/i, ''))
              .filter((x) => x)[0]
          : ''
      }\n${songSubtitle ? `subtitle: ${songSubtitle}\n` : ''}---\n\n${songStr}`
      return [songStr, songFileName]
    })
}

export default function BatchAddSongs() {
  const [value, setValue] = useLocalStorageState('batchaddsongs', '')
  const [preview, setPreview] = useState(false)
  const { createCommit } = useGitLabApi()

  const authCreateFile = useCallback(
    (convertedSongsText: [string, string][]) =>
      createCommit(
        `Batch add ${
          convertedSongsText.length
        } songs\n\n${convertedSongsText
          .map(([, songFilename]) => songFilename)
          .join('\n')}`,
        convertedSongsText.map(([song, songFilename]) => ({
          action: 'create',
          file_path: '/songs/' + songFilename,
          content: song,
        }))
      ),
    [createCommit]
  )
  const [{ loading: creating }, createFiles] = useAsync(
    useCallback(
      (convertedSongsText) =>
        authCreateFile(convertedSongsText)
          .then(() => {
            Toaster.show({
              icon: 'tick',
              intent: Intent.SUCCESS,
              message: 'Create successful',
            })
          })
          .catch((e) => {
            Toaster.show({
              icon: 'warning-sign',
              intent: Intent.DANGER,
              action: e.retry && {
                onClick: () => e.retry(),
                text: 'Retry',
              },
              message: getErrorString(e),
            })
          }),
      [authCreateFile]
    )
  )

  const convertedSongsText = convert(value)

  return (
    <div className={styles.container}>
      <div className={styles.tools}>
        <Button onClick={() => setPreview((p) => !p)} active={preview}>
          Preview
        </Button>
        <Button
          onClick={() => createFiles(convertedSongsText)}
          loading={creating}
        >
          Add all Songs
        </Button>
      </div>
      <TextArea
        className={styles.input}
        placeholder="Enter songs txt here..."
        rows={40}
        large={true}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <Card className={styles.output}>
        {convertedSongsText.map(([song, songFileName], i) => {
          return preview ? (
            <Song key={i} song={parseSong(song)} />
          ) : (
            <TextArea
              key={i}
              className={styles.input}
              style={{ width: '100%' }}
              rows={40}
              large
              readOnly
              value={songFileName + '\n\n' + song}
            />
          )
        })}
      </Card>
    </div>
  )
}
