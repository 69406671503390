import { Song, SongStanza, SongStanzaType } from '../types'

const chorus = (str: string) => `\\beginchorus\n${str}\n\\endchorus`
const verse = (number: number | undefined, str: string) =>
  `\\beginverse${number ? '' : '*'}\n${str}\n\\endverse`
const songWithTitle = (title: string | undefined, str: string) =>
  `\\beginsong{${title}}\n${str}\n\\endsong`

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const getMonthYearString = (dateString: string) => {
  const date = new Date(dateString)
  return date && `${monthNames[date.getMonth()]} ${date.getFullYear()}`
}

const wrapTexMeta = (
  str: string,
  { date = '', footer = '' }: { date: string; footer: string }
) => `\\documentclass[11pt, a4paper]{song-sheet}

\\chead{\\bf\\Large{${date}}}
\\rfoot{${footer}}

\\begin{document}
\\begin{songs}{mainindex}

{\\tam
${str}
}

\\end{songs}
\\end{document}
`

function stanzaToText(stanza: SongStanza): string {
  return stanza.paragraphs
    .map((paragraph) =>
      paragraph.lines
        .map((line) => line.parts.map((part) => part.text).join(''))
        .join('\n')
    )
    .join('\n')
}

function texify(songs: Song[]) {
  return songs
    .map((song) => {
      const content = song.lyrics
        .map((stanza) => {
          if (stanza.type === SongStanzaType.Chorus) {
            // chorus
            return chorus(stanzaToText(stanza))
          } else if (stanza.type === SongStanzaType.Bridge) {
            // bridge
            return chorus(stanzaToText(stanza))
          } else if (stanza.type === SongStanzaType.Verse) {
            // verse with number
            return verse(stanza.number, stanzaToText(stanza))
          }
          return ''
        })
        .join('\n')
      return songWithTitle(song.meta.title, content)
    })
    .join('\n\n')
}

export default function convertSongsToLatex(
  songs: Song[],
  date: string,
  footer: string
) {
  console.log(songs)
  const output = texify(songs)
  return wrapTexMeta(output, {
    date: getMonthYearString(date),
    footer,
  })
}
